import React, { useState, useRef, useEffect } from 'react';
import styles from './Dashboard.module.css';
import { SlCalender } from "react-icons/sl";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import datepicker styles
import Psettings from '../../../components/Psettings';
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from '@chakra-ui/react';
import CameraPTZ from '../../../components/PTZ_Control/CameraPTZ';
import Info from '../../../components/P2P_Info/Info';
import Settings from '../../../components/Camera_Settings/Settings';
import Player from '../../../components/Player/Player';
import JessibucaPlayer from 'react-jessibuca';
import { BsPatchQuestion } from "react-icons/bs";
import { IoSettingsOutline } from "react-icons/io5";

function DeviceView({ device, goBack, url, onDateChange, onPlay, onPause, onDestroy, onFullscreen, onScreenshot, onAudioToggle, onRecording }) {
  const playerRef = useRef(null);
  const [isMuted, setIsMuted] = useState(false);
  const [error, setError] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [isPlaying, setIsPlaying] = useState(true);
  const [playbackSpeed, setPlaybackSpeed] = useState('N/A');
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [calendarOpen, setCalendarOpen] = useState(false);
  const datePickerRef = useRef(null);
  const [plantext, setPlantext] = useState('')
  const [deviceId, setDeviceId] = useState('')
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSettingModalOpen, setIsSettingModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const openSettingModal = () => setIsSettingModalOpen(true);
  const closeSettingModal = () => setIsSettingModalOpen(false);

  const toggleCalendar = () => {
    setCalendarOpen(!calendarOpen);
    // if (calendarOpen) {
    //   datePickerRef.current.setOpen(false);
    // } else {
    //   datePickerRef.current.setOpen(true);
    // }
  };

  const handleCameraSettingsClick = (plantext, deviceId) => {

    setPlantext(plantext);
    setDeviceId(deviceId);
    onOpen();
  };

  const handleDateChange = (date) => {
    if (date) {
      setSelectedDate(date);
      onDateChange(date); // Call the passed onDateChange function with the new date
    }
  };


  useEffect(() => {
    const changeHoverText = () => {
      const elements = document.querySelectorAll('.icon-title-tips .icon-title');
      elements.forEach(element => {
        if (element.textContent === '暂停') {
          element.textContent = 'Pause';
        }
        if (element.textContent === '截图') {
          element.textContent = 'ScreenSort';
        }
        if (element.textContent === '录制') {
          element.textContent = 'Video';
        }
        if (element.textContent === '全屏') {
          element.textContent = 'FullScreen';
        }
        if (element.textContent === '退出全屏') {
          element.textContent = 'Exit';
        }
        if (element.textContent === '播放') {
          element.textContent = 'Play';
        }
        if (element.textContent === '停止录制') {
          element.textContent = 'Stop Recording';
        }
        if (element.textContent === '操作盘激活') {
          element.textContent = 'PTZ';
        }
        if (element.textContent === '操作盘') {
          element.textContent = 'PTZ';
        }
        // 退出全屏
      });
    };

    // Create a MutationObserver to monitor changes in the DOM
    const observer = new MutationObserver(() => {
      changeHoverText();
    });

    // Observe the body for changes in its child elements
    observer.observe(document.body, { childList: true, subtree: true });

    // Initial run to catch elements already in the DOM
    changeHoverText();

    // Cleanup the observer when the component is unmounted
    return () => observer.disconnect();
  }, []);


  const handlePlay = () => {
    // console.log('Play button clicked');
  };

  const handlePause = () => {
    // console.log('Pause button clicked');
  };

  const handleDestroy = () => {
    // console.log('Destroy button clicked');
  };

  const handleFullscreen = () => {
    // console.log('Fullscreen button clicked');
  };

  const handleScreenshot = (screenshotUrl) => {
    // console.log('Screenshot taken:', screenshotUrl);
  };

  const handleAudioToggle = (isMuted) => {
    // console.log('Audio toggled:', isMuted ? 'Muted' : 'Unmuted');
  };

  const handleRecording = (isRecording) => {
    // console.log('Recording status:', isRecording ? 'Recording' : 'Stopped');
  };






  return (
    <>
      <section className={styles.deviceView}>
        <svg onClick={goBack} style={{ cursor: 'pointer', marginTop: '5px' }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3 19V5" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M13 6L7 12L13 18" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M7 12H21" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <header className={styles.deviceHeader}>
          <h2 className={styles.deviceTitle}>{device.cameraname}</h2>
          <ar />
          {/* <time className={styles.deviceTime}>2024-07-11 23:30:02</time> */}
          <div className={styles.dateRangePicker}>
            <div className={styles.datePickerWrapper}>
              <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                dateFormat="yyyy-MM-dd"
                className={styles.dateInput}
                ref={datePickerRef}
                open={calendarOpen}
                onClickOutside={() => setCalendarOpen(false)}
              />
              <SlCalender className={styles.calender} onClick={toggleCalendar} />
            </div>

            <button aria-label="Calendar" className={styles.iconButton} onClick={openModal}>
              <BsPatchQuestion />
            </button>
            <button aria-label="Options" className={styles.iconButton} onClick={() => handleCameraSettingsClick(device.plantext, device.deviceid)}>
              <IoSettingsOutline />
            </button>
            <button aria-label="Options" className={styles.iconButton}
              onClick={openSettingModal}>
              <IoSettingsOutline />
            </button>
          </div>
        </header>

        <div className={styles.deviceComponent} style={{ display: 'flex', justifyContent: 'center' }}>

          <Player
            device={device}
            showControls={true}
            style={{ width: "1220px", height: "720px" }}
            playUrl={url}
            ref={playerRef}
            onPlay={handlePlay}
            onPause={handlePause}
            onDestroy={handleDestroy}
            onFullscreen={handleFullscreen}
            onScreenshot={handleScreenshot}
            onAudioToggle={handleAudioToggle}
            onRecording={handleRecording}
          />

          <div className={`${styles.deviceStatus} ${styles[device.status]}`}>{device.status}</div>
        </div>
      </section>

      {isModalOpen && (
        <Info onClose={closeModal} plantext={plantext} device={device} />
      )}

      {isSettingModalOpen && (
        <Settings onClose={closeSettingModal} plantext={plantext} device={device} />
      )}

      <Modal isOpen={isOpen} onClose={onClose} size="5xl">
        <ModalOverlay />
        <ModalContent >
          <ModalHeader>Camera Settings</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Put your camera settings UI here */}
            <Psettings onClose={onClose} plantext={plantext} deviceid={device.deviceid} />
            {/* <CameraSettings onClose={onClose} plantext={plantext} deviceid={deviceId}  /> */}
          </ModalBody>

        </ModalContent>
      </Modal>


    </>
  );
}

export default DeviceView;
