import React from 'react';
import styles from './Profile.module.css';

function Avatar() {
  return (
    <div className={styles.avatarWrapper}>
      <div className={styles.avatarLarge} aria-label="User avatar">A</div>
      <span className={styles.name}>rahul@gmail.com</span>
    </div>
  );
}

export default Avatar;