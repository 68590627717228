import React from 'react';
import MetricItem from './MetricItem';
import styles from './MetricGroup.module.css';

function MetricGroup() {
  const metrics = [
    {
      title: "Active Devices",
      value: "23",
      change: "+40%",
      changeType: "positive",
      comparisonText: "vs last month",
      chartSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/8044d00edeb35d99afb4e4e69152aba26df7872bc2776ab23eed3214af542960?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1"
    },
    {
      title: "Anomaly detection events",
      value: "1,210",
      change: "-10%",
      changeType: "negative",
      comparisonText: "vs last month",
      chartSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/e8f755d631f9c3d04bf5f567b9e372bad37ee5ecff13ba93c7959f553cfab185?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1"
    },
    {
      title: "Cloud Storage",
      value: "2 TB",
      change: "+20%",
      changeType: "positive",
      comparisonText: "of total allocation",
      chartSrc: "https://cdn.builder.io/api/v1/image/assets/TEMP/5372628d4a14fd8b0778fb6549b17f16ec88e9f1976d4c52c308219fdac0a165?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1"
    }
  ];

  return (
    <section className={styles.metricGroup}>
      {metrics.map((metric, index) => (
        <MetricItem key={index} {...metric} />
      ))}
    </section>
  );
}

export default MetricGroup;