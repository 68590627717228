import axios from "axios";

// const baseURL = 'https://seahorse-app-2-3o2pf.ondigitalocean.app/election';
const baseURL = "https://media.arcisai.io:7000"

const instance = axios.create({
  baseURL: baseURL
});

// export const sendQueryToBackend = async (input, customerId) => {
//     try {
//       console.log(customerId)
//       // Make a POST request to the backend API with the user query
//       const response = await axios.post('https://ai1.ambicam.com:5500/generate_query', {
//         user_input: input,
//         customer_id: customerId
//       });
//       // Return the bot response from the backend
//       console.log(response.data.beautified_sql_result)
//       return response.data.beautified_sql_result;
//     } catch (error) {
//       // Handle error if API call fails
//       console.error('Error fetching response:', error);
//       throw error; // Re-throw the error for the caller to handle
//     }
//   };

export const sendQueryToBackend = async (input, customerId, query, dvr_plan, camera_id) => {
  try {

    // Make a POST request to the backend API with the user query
    const response = await instance.post('/get_results_using_text', {
      query: query,
      dvr_plan: dvr_plan,
      camera_id: camera_id
    });
    // Return the bot response from the backend

    return response;
  } catch (error) {
    // Handle error if API call fails
    console.error('Error fetching response:', error);
    throw error; // Re-throw the error for the caller to handle
  }
};
// "34EFBE77-D1E5-47EE-AB72-B05EEE5179E1"
export const summarizeVideo = async (query_prompt, customerId) => {
  try {


    // Make a POST request to the backend API with the user query
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/pay/summarize-video`, {
      query_prompt: query_prompt,  // Pass the query prompt dynamically
      customerid: customerId  // Corrected key: customer_id
    },
      {
        withCredentials: true
      }
    );

    // Log and return the bot response from the backend

    return response.data;  // Assuming you're interested in the response data
  } catch (error) {
    // Log error and rethrow it for handling
    console.error('Error fetching response:', error);
    throw error;
  }
};


export const chatHistory = async () => {
  try {
    // Make a GET request to the backend API with the user query
    const response = await axios.get('https://goagpt.vmukti.com/export')

    // Return the response from the backend

    return response.data;
  } catch (error) {
    // Handle error if API call fails
    console.error('Error fetching response:', error);
    throw error; // Re-throw the error for the caller to handle
  }
};