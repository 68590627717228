import React from 'react';
import DeviceList from './DeviceList';
import styles from './Dashboard.module.css';

function Dashboard() {
  return (
    <div className={styles.dashboardContainer}>
    
      <main className={styles.mainContent}>
        
        <DeviceList />
      </main>
    </div>
  );
}

export default Dashboard;