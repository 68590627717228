import React from 'react';
import styles from './Profile.module.css';
import Avatar from './Avtar';

function ContactInfo() {
  const contactDetails = [
    { label: 'Email', value: 'rahul@gmail.com' },
    { label: 'Phone', value: 'Bind' },
    { label: 'WeChat', value: 'Bind' }
  ];
  const accountsecurity = [
    {label: 'Change Password', value: 'change the account password'},
    {label : 'Login log', value: 'View the login history'},
    {label: 'Get Help', value: ''},
    { label: 'Heading', value: null },
    {label: 'Line Push Binding', value: 'bind to the Line app for alarm messages'},
    {label: 'Tmall Sprite Authorization', value:'authorize'}
  ]

  return (
    <div className={styles.contactInfo}>
      <Avatar />
      <ul className={styles.contactList}>
        {contactDetails.map((detail, index) => (
          <li key={index} className={styles.contactItem}>
            <span className={styles.contactLabel}>{detail.label}</span>
            {detail.value && <span className={styles.contactValue}>{detail.value}</span>}
          </li>
        ))}
      </ul>

      <ul className={styles.contactlist}>
      <h3>Account Security</h3>
      {accountsecurity.map((detail, index) => (
        <React.Fragment key={index}>
          {detail.value === null ? (
            <h4 className={styles.heading}>Binding/Authorization Section </h4> // Adjust heading level and class as needed
          ) : (
            <li className={styles.contactitem}>
              <span className={styles.contactlabel}>{detail.label}</span>
              {detail.value && <span className={styles.contactvalue}>{detail.value}</span>}
            </li>
          )}
        </React.Fragment>
      ))}
    </ul>
    </div>
  );
}

export default ContactInfo;