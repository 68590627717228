import React from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  Button,
  IconButton,
  Spacer,
  useBreakpointValue,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Avatar,
  AvatarBadge,
  Grid,
  GridItem,
  Divider,
  VStack,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  useDisclosure,
  HStack,
  Link,Badge,
  Tooltip
} from '@chakra-ui/react';
import { useState ,useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { FiMenu, FiSettings, FiBell, FiX, FiHome, FiUser, FiGrid } from 'react-icons/fi';
import { GrMultiple } from 'react-icons/gr';
import NotificationDialog from './NotificationDialog';
import amb from '../assets/images/amb.png'
import {logout} from '../services/auth'

const MobileHeader = ({ headerText }) => {
  const router = useNavigate();
  const [isMobile, setIsMobile] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [email, setEmail] = useState('');

  useEffect(() => {
    // Retrieve user details from local storage
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    if (userDetails) {

      // alert(userDetails.langflag)
      setEmail(userDetails.email);
    }
    // const staticMessage = "Our software is undergoing an upgrade for better performance. During the next 2 days, our downloading service will be temporarily paused. Your recordings are secure with us. For urgent needs, contact our support at `contact@vmukti.com`. We appreciate your understanding.";
    // const initialCount = 1;
    // setNotifications([staticMessage]);
    // setNotificationCount(initialCount);
    
    const socket = new WebSocket('wss://octopus-app-gl75w.ondigitalocean.app:8080'); // Replace with your WebSocket server URL

    // WebSocket event handlers
    socket.addEventListener('open', (event) => {

    });
     // Assuming you're handling incoming messages like this
     socket.addEventListener('message', (event) => {
       const notification = event.data;
       handleIncomingNotification(notification);
       setNotificationCount((prevCount) => prevCount + 1);

     });
 
    socket.addEventListener('close', (event) => {

    });
 
    // Clean up the WebSocket connection when the component unmounts
    return () => {
      socket.close();
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleLogout = async () => {
    // Perform logout logic here
    try {
      // Call the logout API
      const logoutResult = await logout();
  
      if (logoutResult.success) {
        // Remove any items from local storage or perform any other necessary cleanup
        localStorage.removeItem('isLoggedIn');
        localStorage.removeItem('token');

        // Redirect to the homepage after logout
        router('/');
      } else {
        // Handle logout error
        // You can display a message or take appropriate action
        console.error('Logout error:', logoutResult.message);
      }
    } catch (error) {
      // Handle unexpected errors, e.g., network issues
      console.error('Logout error:', error);
    }

  };
  const bottomNavItems = [
    { label: 'Home', icon: <FiHome size={25}/>,tooltip: 'Go to Dashboard', onClick: () => router.push('/dashboard') },
    { label: 'Multiple', icon: <FiGrid size={25}/>, onClick: () => router.push('/multiple') },
    { label: 'Settings', icon: <FiSettings size={25}/>, onClick: () => router.push('/dashboard') },
    { label: 'Profile', icon: <FiUser size={25}/>, onClick: () => router.push('/profile') },
    
    // Add more navigation items as needed
  ];


  const Sidebar = () => (
    <Drawer 
      placement={isMobile ? 'left' : 'left-start'}
      onClose={onClose}
      isOpen={isMobile ? isOpen : true}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader margin={4} borderBottomWidth="1px">
        <Flex justify="space-between" align="center">
            <Box >
                <Flex direction="row" align="center">
               
                <Avatar name={email} bg="blue.200" size="sm">
                    <AvatarBadge boxSize="1.2em" bg="blue.200" />
                </Avatar>
                <Text ml={2} fontWeight="bold">{email.split('@')[0]}</Text>
                </Flex>
            </Box>
            <IconButton
                icon={<FiX />}
                aria-label="Close"
                variant="ghost"
                colorScheme="blue"
                onClick={onClose}
            />
            </Flex>
        </DrawerHeader>
        <DrawerBody>
          <VStack spacing={4} align="start">

            <Button colorScheme="blue" variant="ghost" width="full" onClick={()=>router.push('/dashboard')}>
              Home
            </Button>
            <Button colorScheme="blue" variant="ghost" width="full" onClick={()=>router.push('/profile')}>
              Profile
            </Button>
            <Button colorScheme="blue" variant="ghost" width="full" >
              Cameras
            </Button>
            
            <Button colorScheme="blue" variant="ghost" width="full" onClick={()=>router.push('/multiple')}>
              Multiple Screen
            </Button>
            <Button colorScheme="blue" variant="ghost" width="full" >
              Settings
            </Button>
            <Button colorScheme="blue" variant="ghost" width="full" onClick={handleLogout}>
              Logout
            </Button>
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );

const [isNotificationOpen, setNotificationOpen] = useState(false);
const [notificationCount, setNotificationCount] = useState(0);


const handleNotificationOpen = () => {
  // Increase the notification count and open the dialog
  // setNotificationCount(prevCount => prevCount + 1);
  setNotificationOpen(true);
};

const handleNotificationClose = () => {
  // Close the dialog
  setNotificationOpen(false);
};

const handleMarkAsRead = () => {
  // Reset the notification count
  setNotificationCount(0);
  handleNotificationClose();
};

const [notifications, setNotifications] = useState([]);
  
// Function to handle incoming notifications from the WebSocket server
const handleIncomingNotification = (notification) => {
  setNotifications((prevNotifications) => [...prevNotifications, notification]);
};

  return (<Box bg="gray.300" position="fixed"  borderTopRadius={20}bottom={0}  left={0} right={0} zIndex={10}>
    {/* <Sidebar  /> */}
    <Flex p={4} bg="gray.300" align="center"  position="fixed" top={0} width={'100%'}>
    {/* <IconButton
      icon={<FiMenu />}
      aria-label="Menu"
      variant="ghost"
      colorScheme="blue"
      onClick={onOpen}
    /> */}
    <img height={40} width={40} src={amb}></img>
    <Spacer />
    <Heading size="md">{headerText}</Heading>
    <Spacer />
    <Box position="relative" display="inline-block">
        <Badge
          colorScheme="blue"
          borderRadius="full"
          px={2}
          py={1}
          fontSize="0.8em"
          position="absolute"
          top="-8px"
          right="-8px"
          zIndex={1}
        >
          {notificationCount}
        </Badge>
        <IconButton
          aria-label="Notifications"
          variant="ghost"
          colorScheme="blue"
          onClick={handleNotificationOpen}
        >
          <FiBell size={25}/>
        </IconButton>
      </Box>
      
      {/* Notification Dialog */}
      <NotificationDialog
        isOpen={isNotificationOpen}
        onClose={handleNotificationClose}
        notificationCount={notificationCount}
        onMarkAsRead={handleMarkAsRead}
        notifications={notifications}
      />
  </Flex>
  {/* Bottom Navigation Bar */}
  {isMobile && (
  <Flex bg="grey.300" borderTopRadius={20} style={{ boxShadow: "rgb(255,255,255) 2px 15px 10px 14px" }} p={2} justify="space-around" align="center">
    {bottomNavItems.map((item, index) => (
      <Box key={index} textAlign="center" onClick={item.onClick} style={{  width:"65px", borderRadius: "20px", backgroundColor: item.isActive ? "#f0f0f0" : "transparent" }}>
      
        <IconButton
            // aria-label={item.label}
            variant="ghost"
            colorScheme="blue"
            // color={item.color} // Apply color to the icon
            // onClick={item.onClick}
            icon={item.icon} // Assign the icon to IconButton
          >
        </IconButton>
        
      </Box>
    ))}
  </Flex>
)}

  {/* {isMobile && (
        <Flex bg="grey.300" borderTopRadius={20}  style={{boxShadow:"2px 20px 10px 14px"}} p={3.5} justify="space-around" align="center">
          {bottomNavItems.map((item, index) => (
            <Box key={index} textAlign="center"  onClick={item.onClick}>
              <div style={{marginLeft:"25%"}}>{item.icon}</div>
             
            </Box>
          ))}
        </Flex>
      )} */}
  
  
  </Box>
  );
};

export default MobileHeader;