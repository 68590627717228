import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import withAuth from '../components/withAuth';
import {
  Box, SimpleGrid, useColorModeValue, CloseButton,
  Flex,
  Text,
  Divider, Icon,
  useDisclosure,
  Link,
  Grid,
  theme
} from '@chakra-ui/react';
import { FiSettings } from 'react-icons/fi';
import { BsCameraVideo, BsBell, BsCloudArrowDown, BsPeople, BsFillCarFrontFill } from 'react-icons/bs';
import { TbFaceId, TbArrowsDiff } from 'react-icons/tb';
import { AiOutlineHeatMap } from 'react-icons/ai';
import { GrMultiple } from 'react-icons/gr';
import { OverviewBudget } from '../components/OverviewBudget';
import { getCardData, getChartDataByCustomerId, getOnOffCameraData, getPromptData, getTotalCamera, imageCount } from '../actions/dashboardActions';
import MetricItem from '../components/Reports/MetricItem';
import DashboardCard from '../components/Reports/DashboardCard';
import { getAiCameraList } from '../services/aiCamera';
import { AttachmentIcon } from '@chakra-ui/icons';
import { AiFillVideoCamera } from "react-icons/ai";
import { GoDotFill } from "react-icons/go";
import { FcOldTimeCamera } from "react-icons/fc";
import { color } from 'framer-motion';
import BarChartComponent from '../components/BarChartComponent';
import PieChartComponent from '../components/PieChartComponent';


const LinkItems = [
  { name: 'Cameras', icon: BsCameraVideo },
  { name: 'Multiple', icon: GrMultiple, path: '/multiple' },
  { name: 'Events', icon: BsBell },
  { name: 'Archive Export', icon: BsCloudArrowDown },
  { name: 'Faces', icon: TbFaceId },
  { name: 'Heatmap', icon: AiOutlineHeatMap },
  { name: 'People', icon: BsPeople },
  { name: 'Visitors', icon: TbArrowsDiff },
  { name: 'ANPR', icon: BsFillCarFrontFill },
  { name: 'Settings', icon: FiSettings },
];

const Dashy = () => {
  const router = useNavigate();
  const [offCameraCount, setOffCameraCount] = useState(0);
  const [onCameraCount, setOnCameraCount] = useState(0);
  const [metricss, setMetricss] = useState([]);
  const [metricsss, setMetricsss] = useState([]);
  const [cameraIds, setCameraIds] = useState([]);

  const barChartOptions = {
    chart: {
      type: 'bar',
      height: '100%',
      background: 'transparent',
      stacked: false,
      // toolbar: {
      //   show: false
      // }
    },
    colors: [theme.colors.purple[500], theme.colors.blue[500]],

    // title is sent separately...

    // title: {
    //   text: 'Monthly Sales Data Comparison',
    //   align: 'left',
    // },
    fill: {
      opacity: 1,
      type: 'solid'
    },
    grid: {
      strokeDashArray: 1,
      xaxis: {
        lines: {
          show: false
        }
      },
      yaxis: {
        lines: {
          show: true
        }
      }
    },
    xaxis: {
      categories: cameraIds,
    },
    legend: {
      position: 'top',
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '30px',
      },
    }
  };
  const barChartTitle = 'Camera Id wise Total Human Count';
  // const barChartSeries = [
  //   {
  //     name: 'Sales 2023',
  //     data: [65, 59, 80, 81, 56, 55, 65],
  //   }
  //   // ,
  //   // {
  //   //   name: 'Sales 2024',
  //   //   data: [75, 69, 70, 91, 66, 65],
  //   // },
  // ];
  const [barChartSeries, setBarChartSeries] = useState([
    {
      name: 'Image Counts',
      data: [], // This will be updated with img_count values
    }
  ]);

  const fetchChartDataByCustomerId = async (customerId) => {
    try {
      const response = await getChartDataByCustomerId(customerId);
      const cameraImgCounts = response.data.cameraImgCounts;

      // Extracting camera IDs and image counts
      const cameraIds = cameraImgCounts.map(item => item.cameraid);
      setCameraIds(cameraIds);

      setBarChartSeries([
        {
          name: 'Image Counts',
          data: cameraImgCounts.map(item => item.img_count), // Mapping img_counts directly
        }
      ]);



    } catch (error) {
      console.error('Error:', error);
      router.push('/404');
    }
  };

  const [pieChartSeries, setPieChartSeries] = useState([]);

  const pieChartOptions = {
    chart: {
      type: 'pie',
      height: '100%',
    },
    colors: [theme.colors.purple[500], theme.colors.blue[500]],
    labels: ['Online Camera', 'Offline Camera'],
    title: {
      text: 'Camera Status',
      align: 'left',
    },
    legend: {
      position: 'top',
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
      },
    },
    tooltip: {
      fillSeriesColor: false,
    },
    states: {
      active: {
        filter: {
          type: 'none',
        },
      },
      hover: {
        filter: {
          type: 'none',
        },
      },
    },
  };

  const fetchCardData = async (customerId) => {
    try {
      const response = await getCardData(customerId);
      const data = response.data.uiModelData;

      const onlineCamera = data.find((item) => item.title === 'Online Camera');
      const offlineCamera = data.find((item) => item.title === 'Offline Camera');

      // Update the pieChartSeries state
      setPieChartSeries([
        onlineCamera ? parseInt(onlineCamera.value, 10) : 0,
        offlineCamera ? parseInt(offlineCamera.value, 10) : 0,
      ]);

      setMetricss(data);
    } catch (error) {
      console.error('Error:', error);
      router.push('/404');
    }
  };

  const fetchPromptData = async (customerId) => {
    try {
      const response = await getPromptData(customerId);
      setMetricsss(response.data.promptQuery);
    } catch (error) {
      console.error('Error:', error);
      router.push('/404');
    }
  };

  const fetchAiCameraCount = async (customerId) => {
    try {
      const response = await getAiCameraList(customerId, 1, 1000);

    } catch (error) {
      console.error('Error:', error);
      router.push('/404');
    }
  };

  const fetchImageCount = async (customerId) => {
    try {
      const response = await imageCount(customerId);
      // console.log('image count', response);
    } catch (error) {
      console.error('Error:', error);
      router.push('/404');
    }
  };

  const fetchOnOff = async (customerId) => {
    try {
      const response = await getOnOffCameraData(customerId);
      // console.log('on off data', response.data);
      setOnCameraCount(response.data.onlineCount);
      setOffCameraCount(response.data.offlineCount);
    } catch (error) {
      console.error('Error:', error);
      router.push('/404');
    }
  };

  useEffect(() => {
    // const userDetails = localStorage.getItem('userDetails');
    const userDetails = localStorage.getItem('userId');
    if (userDetails) {
      // Parse the JSON string to get the object
      // const user = JSON.parse(userDetails);
      // Access the customerid
      const customerId = localStorage.getItem('userId');
      // console.log('Customer ID:', customerId);
      // fetchCameraList(customerId);
      fetchAiCameraCount(customerId);
      fetchCardData(customerId);
      fetchImageCount(customerId);
      fetchOnOff(customerId);
      fetchPromptData(customerId);
      fetchChartDataByCustomerId(customerId);
    } else {

      alert('No user details found in localStorage.');
    }
  }, []);

  return (
    <>
      <Box ml={{ base: '2%', md: '10%', lg: '16%' }}
        mr={{ base: '2%', md: '5%', lg: '9%' }} mt={'3%'}>
        {/* <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={6}>
          <OverviewBudget
            difference={12}
            positive
            sx={{ height: '100%' }}
            value={`${totalCameras}`}
            sanand={`${sanandTotal}`}
            title={`Total Cameras`}
            iconColor='white'
            color='#7F56D9'
            icon={AttachmentIcon} // Add the icon here
          />
          <OverviewBudget
            difference={12}
            positive
            sx={{ height: '100%' }}
            value={`${totalcamerass}`}
            sanand={`${sanandTotal}`}
            title={`Total Cameras`}
            iconColor='white'
            color='#7F56D9'
            icon={AttachmentIcon} // Add the icon here
          />
          <OverviewBudget
            difference={12}
            positive
            sx={{ height: '100%' }}
            value={`${totalcamerass}`}
            sanand={`${sanandTotal}`}
            title={`Total Cameras`}
            iconColor='white'
            color='#7F56D9'
            icon={AttachmentIcon} // Add the icon here
          />
          <OverviewBudget
            difference={12}
            positive
            sx={{ height: '100%' }}
            value={`${totalcamerass}`}
            sanand={`${sanandTotal}`}
            title={`Total Cameras`}
            iconColor='white'
            color='#7F56D9'
            icon={AttachmentIcon} // Add the icon here
          />
        </SimpleGrid> */}
      </Box>

      <Box>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={6} padding="0.5% 2%">
          {metricss.map((metric, index) => (
            <DashboardCard key={index} {...metric} />
          ))}
          {metricsss.map((metric, index) => (
            <DashboardCard
              key={index}
              title={metric.promptQuery}
              value={metric.totalImageCount}
              IconComponent={"GoDotFill"}
              color="#7F56D9"
            />
          ))}
        </SimpleGrid>
      </Box>

      {/* <Box>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={6} padding="0.5% 2%">
         
        </SimpleGrid>
      </Box> */}

      <Grid
        width="100%"
        templateColumns={{
          base: "repeat(1, 1fr)",
          xl: "2.5fr 1.5fr",
          lg: "2.5fr 1.5fr",
          md: "1fr 1fr",
          sm: "1fr 1fr",
        }}
        gap={6}
        padding="2% 2%"
        height="500px"
      >
        <Box height="100%" display="flex" flexDirection="column">
          <Box flex="1">
            <BarChartComponent
              options={barChartOptions}
              series={barChartSeries}
              title={barChartTitle}
            />
          </Box>
        </Box>
        <Box height="100%" display="flex" flexDirection="column">
          <Box flex="1">
            <PieChartComponent
              options={pieChartOptions}
              series={pieChartSeries}
            />
          </Box>
        </Box>
      </Grid>

      <Box pt={15}>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={6}>
          {/* <DashboardCard
            title="Total Cameras"
            value={aiTotalCameras}
            change="+40%"
            changeType="positive"
            comparisonText="vs last month"
            chartSrc="https://cdn.builder.io/api/v1/image/assets/TEMP/8044d00edeb35d99afb4e4e69152aba26df7872bc2776ab23eed3214af542960?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1"
            IconComponent={AiFillVideoCamera}
          /> */}
        </SimpleGrid>
      </Box>
    </>
  );
};

export default Dashy
