import React from 'react';
import styles from './LoginPage.module.css';
import Header from './Header';
import LoginForm from './LoginForm';
import SocialLogin from './SocialLogin';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';

function LoginPage() {

  const navigate = useNavigate(); // Get the navigate function

  const handleSignUpClick = () => {
    navigate('/signup'); // Navigate to the signup page
  };
  return (
    <div className={styles.loginPage}>
      <div className={styles.loginContent}>
        <div className={styles.loginSection}>
          <Header />
          <main className={styles.mainContent}>
            <div className={styles.formContainer}>
              <h1 className={styles.title}>Log in</h1>
              <p className={styles.supportingText}>
                Welcome back! Please enter your details and get access to your digital vision.
              </p>
              <LoginForm />
              {/* <SocialLogin /> */}
              <div className={styles.signupPrompt}>
                <span>Don't have an account?</span>
                <button className={styles.signupLink}  onClick={handleSignUpClick}>Sign up</button>
              </div>
            </div>
          </main>
          <Footer />
        </div>
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/3a97765940e63e717f826a53e36f099412c1f2713d32db196da2c2d070ec999e?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1" alt="" className={styles.sideImage} />
      </div>
    </div>
  );
}

export default LoginPage;