import React from 'react';
import styles from './Dashboard.module.css';

function Timeline() {
  const dates = ['03/08', '04/08', '05/08', '06/08', '07/08', '08/08', '09/08', '10/08'];

  return (
    <div className={styles.timeline}>
      <div className={styles.timelineHeader}>
        {dates.map((date, index) => (
          <div key={index} className={styles.dateColumn}>
            <span className={styles.date}>{date}</span>
          </div>
        ))}
      </div>
      <div className={styles.timelineBody}>
        {dates.map((_, index) => (
          <div key={index} className={styles.timeColumn}>
            <div className={styles.timeMark}></div>
            <div className={styles.timeLabel}>07:00</div>
          </div>
        ))}
      </div>
      <div className={styles.eventIndicator}>
        <div className={styles.eventBar}></div>
      </div>
    </div>
  );
}

export default Timeline;