import React, { useEffect, useState } from 'react';
import { useToast } from '@chakra-ui/react';
import useWebSocket from 'react-use-websocket';
import { getCustomerCameraList } from '../services/getcamera';

const fetchData = async (page) => {

  const customerId = localStorage.getItem('userId');
  let resultPerPage = 90;

  if (window.innerWidth > 1645) {
    resultPerPage = 120; // Adjust the result per page based on screen width
  }

  try {
    // Fetch camera list data
    const result = await getCustomerCameraList(customerId, page, resultPerPage);
    if (result.success) {
      // Return the camera list data directly
      return result.cameras;
    }
  } catch (error) {
    console.error('Error fetching camera list:', error);
  }

  return []; // Return an empty array in case of an error
};


const WebSocketComponent = ({position}) => {
  const [devices, setDevices] = useState([]);
  useEffect(() => {
    const loadDevices = async () => {
      const data = await fetchData(1);

      setDevices(data); // Set the devices state
    };

    loadDevices(); // Call the loadDevices function
  }, []);



  const toast = useToast();
  const { lastMessage } = useWebSocket('wss://alert.arcisai.io:5080');

  const formatEventType = (eventType) => {
    switch (eventType) {
      case 'HD':
        return 'Human Detection';
      case 'FD':
        return 'Face Detection';
      default:
        return eventType; // Return the original value if no match
    }
  };

  useEffect(() => {
    if (lastMessage !== null) {
      try {
        const message = JSON.parse(lastMessage.data);

        // Extract and format data from the message
        const deviceSN = message.deviceSN || 'N/A';
        const matchingDevice = devices.find(device => device.deviceid === deviceSN);

        if (matchingDevice) {
        const timeStamp = message.timeStamp || 'N/A';
        const eventType = formatEventType(message.eventType || 'N/A');

        toast({
          title: 'AI Alert',
          description: (
            <div>
              <p><strong>Device SN:</strong> {deviceSN}</p>
              <p><strong>Time:</strong> {timeStamp}</p>
              <p><strong>Event Type:</strong> {eventType}</p>
            </div>
          ),
          status: 'info',
          duration: 5000,
          isClosable: true,
          position:position,
          // position: 'top-right',
          colorScheme:'#7f56d9',
          containerStyle: {
            borderRadius:'15px',
            backgroundColor: '#7f56d9',
            borderColor:'black',
            color: 'white',
          },
        });
      }
      } catch (error) {
        console.error('Failed to parse WebSocket message:', error);
      }
    }
  }, [lastMessage, toast, devices, position]);

  return null; // Return null to render nothing
};

export default WebSocketComponent;
