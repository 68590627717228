import React from "react";
import styles from './MultiScreenDesktop.module.css';

import MainContent from './MainContent';

function MultiScreenDesktop() {
  return (
    <div className={styles.multiScreenDesktop}>
      <main className={styles.mainContent}>
     
        <MainContent />
      </main>
    </div>
  );
}

export default MultiScreenDesktop;