import React, { useEffect, useState } from "react";

import axios from "axios";
import AreaCanvas from "../../AreaCanvas";
import styles from "../Settings.module.css";

function AreaDetection({ deviceId }) {
  const [isEnabled, setIsEnabled] = useState(false);
  const [alarmSound, setAlarmSound] = useState();
  const [whiteLightAlarm, setWhiteLightAlarm] = useState();
  const [appMessagePush, setAppMessagePush] = useState();
  const [rtmpPush, setRtmpPush] = useState();
  const [ftpPush, setFtpPush] = useState();
  const [direction, setDirection] = useState("");
  const [RepeatAlarmTime, setRepeatAlarmTime] = useState();
  const [Sensitivity, setSensitivity] = useState();
  const [MinDuration, setMinDuration] = useState();
  const [error, setError] = useState(null);
  const [Action, setAction] = useState("");
  const [DetectRegion, setDetectRegion] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/p2p/getAreaDetection`,
          {
            params: {
              deviceId: deviceId,
            },
          }
        );

        setIsEnabled(response.data.Enabled);
        setRtmpPush(response.data.AlarmOut.RtmpPush.Enabled);
        setFtpPush(response.data.AlarmOut.FtpPush.Enabled);
        setAlarmSound(response.data.AlarmOut.AudioAlert.Enabled);
        setWhiteLightAlarm(response.data.AlarmOut.LightAlert.Enabled);
        setAppMessagePush(response.data.AlarmOut.AppPush.Enabled);
        setAction(response.data.Action);
        setMinDuration(response.data.MinDuration);
        setRepeatAlarmTime(response.data.RepeatAlarmTime);
        setSensitivity(response.data.Sensitivity);
        setDirection(response.data.Direction);
        setDetectRegion(response.data.DetectRegion);

      } catch (error) {
        setError(error);
      }
    };

    fetchData();
  }, [deviceId]);

  const handleSave = async () => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/p2p/setAreaDetection`,
        {
          Enabled: isEnabled,
          DetectRegion: DetectRegion,
          DetectObj: "Human",
          Direction: direction,
          AlarmOut: {
            AudioAlert: {
              Enabled: alarmSound,
            },
            LightAlert: {
              Enabled: whiteLightAlarm,
            },
            AppPush: {
              Enabled: appMessagePush,
            },
            RtmpPush: {
              Enabled: rtmpPush,
            },
            FtpPush: {
              Enabled: ftpPush,
            },
            Email: {
              Enabled: false,
            },
            gat1400: {
              Enabled: false,
            },
          },
          Action: Action,
          MinDuration: MinDuration,
          RepeatAlarmTime: RepeatAlarmTime,
          Sensitivity: Sensitivity,
        },
        {
          params: { deviceId: deviceId },
        }
      );


    } catch (error) {
      console.error("Error saving settings", error);
    }
  };

  const handleDirectionChange = (event) => {
    setDirection(event.target.value);
  };

  const openCanvasModal = () => {
    setIsCanvasModalOpen(true);
  };

  const closeCanvasModal = () => {
    setIsCanvasModalOpen(false);
  };

  const [isCanvasModalOpen, setIsCanvasModalOpen] = useState(false);
  const handleCanvasData = (region, direction) => {
    setDetectRegion(region);
    setDirection(direction);
  };
  return (
    <div className={styles.notify}>
       <div className={styles.checkboxContainer}  style={{ marginBottom: "16px",color:'#7F56D9' }}>
        <label style={{ fontSize: "18px", fontWeight: "bold" }}> Area Detection</label>
      </div>

      <div style={{ marginBottom: "7px", display: "flex", alignItems: "center" }}>
        <label htmlFor="enabled"  style={{ flex: "0 0 150px", marginRight: "16px", fontWeight: "bold" }}>
          Enabled
        </label>
        <input
          type="checkbox"
          id="enabled"
          onChange={(e) => setIsEnabled(e.target.checked)}
          style={{
            border: "1px solid #ccc",
            padding: "8px",
            borderRadius: "4px",
            fontSize: "14px"
          }}
        />
      </div>

      <div style={{ marginBottom: "7px", display: "flex", alignItems: "center" }}>
        <label htmlFor="alarmSound"   style={{ flex: "0 0 150px", marginRight: "16px", fontWeight: "bold" }}>
          Enable Alarm Sound
        </label>
        <input
          type="checkbox"
          id="alarmSound"
          onChange={(e) => setAlarmSound(e.target.checked)}
          style={{
            border: "1px solid #ccc",
            padding: "8px",
            borderRadius: "4px",
            fontSize: "14px"
          }}
        />
      </div>

      <div style={{ marginBottom: "7px", display: "flex", alignItems: "center" }}>
        <label htmlFor="whiteLight"   style={{ flex: "0 0 150px", marginRight: "16px", fontWeight: "bold" }}>
          Enable White Light alarm sound
        </label>
        <input
          type="checkbox"
          id="whiteLightAlarm"
          onChange={(e) => setWhiteLightAlarm(e.target.checked)}
          style={{
            border: "1px solid #ccc",
            padding: "8px",
            borderRadius: "4px",
            fontSize: "14px"
          }}
        />
      </div>

      <div style={{ marginBottom: "7px", display: "flex", alignItems: "center" }}>
        <label htmlFor="whiteLight"   style={{ flex: "0 0 150px", marginRight: "16px", fontWeight: "bold" }}>
          Enable app message push
        </label>
        <input
          type="checkbox"
          id="appMessagePush"
          onChange={(e) => setAppMessagePush(e.target.checked)}
          style={{
            border: "1px solid #ccc",
            padding: "8px",
            borderRadius: "4px",
            fontSize: "14px"
          }}
        />
      </div>

      <div style={{ marginBottom: "7px", display: "flex", alignItems: "center" }}>
        <label htmlFor="whiteLight"   style={{ flex: "0 0 150px", marginRight: "16px", fontWeight: "bold" }}>
          Enable RTMP push
        </label>
        <input
          type="checkbox"
          id="rtmpPush"
          onChange={(e) => setRtmpPush(e.target.checked)}
          style={{
            border: "1px solid #ccc",
            padding: "8px",
            borderRadius: "4px",
            fontSize: "14px"
          }}
        />
      </div>

      <div style={{ marginBottom: "7px", display: "flex", alignItems: "center" }}>
        <label htmlFor="whiteLight"   style={{ flex: "0 0 150px", marginRight: "16px", fontWeight: "bold" }}>
          Enable FTP push
        </label>
        <input
          type="checkbox"
          id="ftpPush"
          onChange={(e) => setFtpPush(e.target.checked)}
          style={{
            border: "1px solid #ccc",
            padding: "8px",
            borderRadius: "4px",
            fontSize: "14px"
          }}
        />
      </div>

      <div style={{ marginBottom: "7px", display: "flex", alignItems: "center" }}>
        <button style={{
        backgroundColor: "#7F56D9",
        color: "white",
        padding: "8px 12px",
        border: "none",
        borderRadius: "4px",
        cursor: "pointer",
        fontWeight: "bold"
      }} onClick={handleSave}>Save</button>
      </div>

      <div>
        <button style={{
        backgroundColor: "#88ffcc",
        color: "black",
        padding: "8px 12px",
        border: "none",
        borderRadius: "4px",
        cursor: "pointer",
        fontWeight: "bold"
      }} onClick={openCanvasModal}>
          Open Canvas
        </button>
      </div>

     
    </div>
  );
}

export default AreaDetection;
