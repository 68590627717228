import React, { useState, useRef, useEffect } from 'react';
import {
  Flex,
  Box,
  Text,
  FormControl,
  FormLabel,
  Checkbox,
  Select,
  Button,
  Input
} from '@chakra-ui/react';
import CanvasModal from './LineCrossCanvas';
import axios from 'axios';
import LineCrossCanvas from './LineCrossCanvas';
import AreaCanvas from './AreaCanvas';
import UAOCanvas from './UAOCanvas';
import MODCanvas from './MODCanvas';

const MODetection = ({deviceId}) => {
  const [isEnabled, setIsEnabled] = useState(false);
  const [alarmSound, setAlarmSound] = useState();
  const [whiteLightAlarm, setWhiteLightAlarm] = useState();
  const [appMessagePush, setAppMessagePush] = useState();
  const [rtmpPush, setRtmpPush] = useState();
  const [ftpPush, setFtpPush] = useState();
  const [direction, setDirection] = useState('');
  const [RepeatAlarmTime, setRepeatAlarmTime] = useState();
  const [Sensitivity, setSensitivity] = useState();
  const [MinDuration, setMinDuration] = useState();
  const [error, setError] = useState(null);
  const [Action, setAction] = useState('');
  const [DetectRegion, setDetectRegion] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/p2p/getMissingObjectDetection`,{
          params: {
            deviceId: deviceId
          }});
         
          setIsEnabled(response.data.Enabled)
          setRtmpPush(response.data.AlarmOut.RtmpPush.Enabled)
          setFtpPush(response.data.AlarmOut.FtpPush.Enabled)
          setAlarmSound(response.data.AlarmOut.AudioAlert.Enabled)
          setWhiteLightAlarm(response.data.AlarmOut.LightAlert.Enabled)
          setAppMessagePush(response.data.AlarmOut.AppPush.Enabled)
          setMinDuration(response.data.Duration)
          setSensitivity(response.data.Sensitivity)
          setDetectRegion(response.data.DetectAera);

        
      } catch (error) {
        setError(error);
      }
    };

    fetchData();
  }, [deviceId]); 

  const handleSave = async () => {
    try {
      const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/p2p/setMissingObjectDetection`, {
        Enabled: isEnabled,
        DetectRegion: DetectRegion,
        MinDuration:MinDuration,
        Sensitivity:Sensitivity,
        AlarmOut: {
          AudioAlert: {
            Enabled: alarmSound,
          },
          LightAlert: {
            Enabled: whiteLightAlarm,
          },
          AppPush: {
            Enabled: appMessagePush,
          },
          RtmpPush: {
            Enabled: rtmpPush,
          },
          FtpPush: {
            Enabled: ftpPush,
          },
          Email: {
            Enabled: false,
          },
          gat1400: {
            Enabled: false,
          },
        },
       
      }, {
        params: { deviceId: deviceId }
      });


    } catch (error) {
      console.error('Error saving settings', error);
    }
  };


  const handleDirectionChange = (event) => {
    setDirection(event.target.value);
  };

  const openCanvasModal = () => {
    setIsCanvasModalOpen(true);
  };

  const closeCanvasModal = () => {
    setIsCanvasModalOpen(false);
  };
  
  const [isCanvasModalOpen, setIsCanvasModalOpen] = useState(false);
  const handleCanvasData = (region, direction) => {
    setDetectRegion(region);
    setDirection(direction);
  };

  return (
    <Flex direction="column" padding="10px" gap={4}>
    <Box>
      <Text fontWeight="bold" fontSize="lg" color="blue.500">
            Missing Object Detection
      </Text>
    </Box>
    <Flex>
      <FormControl display="flex" alignItems="center">
        <FormLabel marginBottom="0" mr={4}>
          Enable
        </FormLabel>
        <Checkbox isChecked={isEnabled} onChange={(e) => setIsEnabled(e.target.checked)} />
      </FormControl>
    </Flex>
    <Flex>
      <FormControl display="flex" alignItems="center">
        <FormLabel marginBottom="0" mr={4}>
          Enable alarm Sound
        </FormLabel>
        <Checkbox isChecked={alarmSound} onChange={(e) => setAlarmSound(e.target.checked)} />
      </FormControl>
    </Flex>
    <Flex>
      <FormControl display="flex" alignItems="center">
        <FormLabel marginBottom="0" mr={4}>
          Enable white light alarm sound
        </FormLabel>
        <Checkbox isChecked={whiteLightAlarm} onChange={(e) => setWhiteLightAlarm(e.target.checked)} />
      </FormControl>
    </Flex>
    <Flex>
      <FormControl display="flex" alignItems="center">
        <FormLabel marginBottom="0" mr={4}>
          Enable app message push
        </FormLabel>
        <Checkbox isChecked={appMessagePush} onChange={(e) => setAppMessagePush(e.target.checked)} />
      </FormControl>
    </Flex>
    <Flex>
      <FormControl display="flex" alignItems="center">
        <FormLabel marginBottom="0" mr={4}>
          Enable RTMP push
        </FormLabel>
        <Checkbox isChecked={rtmpPush} onChange={(e) => setRtmpPush(e.target.checked)} />
      </FormControl>
    </Flex>
    <Flex>
      <FormControl display="flex" alignItems="center">
        <FormLabel marginBottom="0" mr={4}>
          Enable FTP push
        </FormLabel>
        <Checkbox isChecked={ftpPush} onChange={(e) => setFtpPush(e.target.checked)} />
      </FormControl>
    </Flex>
    <Flex>
      <FormControl display="flex" alignItems="center">
        <FormLabel marginBottom="0" mr={4}>
         Minimum Duration(s)
        </FormLabel>
        <Input  placeholder={MinDuration} width="auto"/>
      </FormControl>
    </Flex>
    <Flex direction="row" align="center" gap={4}>
              <FormControl display="flex" alignItems="center">
                <FormLabel marginBottom="0">Sensitivity Level</FormLabel>
                <Select width="auto" value={Sensitivity} onChange={(e) => setSensitivity(Number(e.target.value))}>
            {Array.from({ length: 11 }, (_, i) => (
              <option key={i} value={i}>
                {i}
              </option>
            ))}
          </Select>
              </FormControl>
            </Flex>
    <Box mt={4}>

    <Box mt={4}>
        <Button colorScheme="teal" onClick={openCanvasModal}>
          Open Canvas
        </Button>
      </Box>
      <MODCanvas isOpen={isCanvasModalOpen}  onClose={closeCanvasModal} onCanvasData={handleCanvasData} 
      existingCoordinates={DetectRegion}
      deviceId={deviceId}
      />
<Box mt={4}>
        <Button colorScheme="blue" onClick={handleSave}>Save</Button>
      </Box>
</Box>
    </Flex>
  );
};

export default MODetection;
