import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useMediaQuery } from '@chakra-ui/react';
import styles from './ProfileInfo.module.css';
import { CgProfile } from "react-icons/cg";
import { IoChevronForwardSharp } from "react-icons/io5";
import { IoSettingsOutline } from "react-icons/io5";
import { FiShoppingBag } from "react-icons/fi";
import { AiOutlineDollar } from "react-icons/ai";
import { CiPen } from "react-icons/ci";
import { GoVideo } from "react-icons/go";
import { CiShare2 } from "react-icons/ci";
import { CiHeadphones } from "react-icons/ci";
import { MdSystemUpdate } from "react-icons/md";
import { RiMapPinLine } from "react-icons/ri";


function ProfileInfo() {
  const navigate = useNavigate();
  const [isMobile] = useMediaQuery('(max-width: 48em)'); // Mobile view breakpoint


  const profilesectionclick = () => {
    if (isMobile) {
      navigate('/profileSection'); // Navigate to ProfileSection page
    }
  };

  const signoutclick = () => {
    navigate('/');
  }

  const settingclick = () => {
    navigate('/settingpage');
  }

  const mapclick = () => {
    navigate('/geomap');
  }

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOrderModalOpen, setIsOrderModalOpen] = useState(false);
  const [isDemoModalOpen, setIsDemoModalOpen] = useState(false);
  const [isShotModalOpen, setIsShotModalOpen] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
  const [isAuthorityModalOpen, setIsAuthorityModalOpen] = useState(false);
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(!isModalOpen);
  const openOrderModal = () => setIsOrderModalOpen(!isOrderModalOpen);
  const openDemoModal = () => setIsDemoModalOpen(!isDemoModalOpen);
  const openShotModal = () => setIsShotModalOpen(!isShotModalOpen);
  const openShareModal = () => setIsShareModalOpen(!isShareModalOpen);
  const openHelpModal = () => setIsHelpModalOpen(!isHelpModalOpen);
  const openAuthorityModal = () => setIsAuthorityModalOpen(!isAuthorityModalOpen);
  const openPrivacyModal = () => setIsPrivacyModalOpen(!isPrivacyModalOpen);
  return (
    <div>
      {/* Desktop View */}
      <section className={styles.profileInfo}>
        <h1 className={styles.profileTitle}>
          Profile
          <div className={styles.signOutSection}>
            <p>signout</p>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/c24ffecf29109f05ead6f11505462d0bb44b18d914f9aa46fea6c17ddeeab3d7?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1"
              alt="Sign out icon"
              className={styles.signOutIcon}
              onClick={signoutclick}
            />
          </div>
        </h1>
        <p className={styles.profileDescription}>List of events triggered by cameras</p>
        <div className={styles.profileDetails}>
          <div className={styles.userInfo}>
            <div className={styles.avatar}>
              <div className={styles.avatarPlaceholder}>A</div>
            </div>
            <div className={styles.bindingInfo}>
              <p>rahul@gmail.com</p>
            </div>
          </div>
        </div>
        <div className={styles.contactInfo}>
          <p>Email</p>
          <p>rahul@gmail.com</p>
          <p>Phone</p>
          <p>Bind</p>
          <p>WeChat</p>
          <p>Bind</p>
        </div>
      </section>

      {/* Mobile View */}
      <div className={styles.mobileView}>

        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <h1>Account
              <div className={styles.signOutSection}>
                <p>signout</p>
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/c24ffecf29109f05ead6f11505462d0bb44b18d914f9aa46fea6c17ddeeab3d7?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1"
                  alt="Sign out icon"
                  className={styles.signOutIcon}
                  onClick={signoutclick}
                />
              </div>
            </h1>
            <div className={styles.accountdetails}>
              <div className={styles.profileDetails}>
                <div className={styles.userInfo}>
                  <div className={styles.avatar}>
                    <div className={styles.avatarPlaceholder}>A</div>
                  </div>
                  <div className={styles.bindingInfo}>
                    <p>rahul@gmail.com</p>
                  </div>
                </div>
              </div>
              <div className={styles.accountlist}>
                {isMobile && (
                  <p onClick={profilesectionclick} style={{ cursor: 'pointer' }} className='profile-trigger'>
                    <CgProfile /> &nbsp;Profile
                  </p>
                )}               
                 <p><IoChevronForwardSharp /></p>
                <p onClick={settingclick}><IoSettingsOutline /> &nbsp;Settings</p>
                <p><IoChevronForwardSharp /></p>
                <p onClick={openOrderModal}><FiShoppingBag /> &nbsp;My Orders</p>
                <p><IoChevronForwardSharp /></p>

                {isOrderModalOpen && (
                  <div className={styles.modal}>
                    <div className={styles.modalContent}>
                      <h1>My Orders</h1>
                      <p className={styles.order}>
                        <p><FiShoppingBag /> &nbsp;Camera Nikon 232 <br />
                          <span className={styles.minidescription}>processing
                            <span>Unpaid</span></span></p>
                        <p>$32.01</p>
                        <p><FiShoppingBag /> &nbsp;Dashboard Subscription <br />
                          <span className={styles.minidescription}>completed
                            <span>paid</span></span></p>
                        <p>$32.01</p>
                      </p>
                    </div>
                  </div>
                )}
                <p onClick={mapclick}><RiMapPinLine /> &nbsp;Open Map</p>
                <p><IoChevronForwardSharp /></p>
                <p onClick={openDemoModal}><CiPen /> &nbsp;Demo Center</p>
                <p><IoChevronForwardSharp /></p>

                {isDemoModalOpen && (
                  <div className={styles.modal}>
                    <div className={styles.modalContent}>
                      <h1 className={styles.Demo}>Demo Center
                        <div className={styles.filter}>
                          <select>
                            <option value='filter'>Filter</option>
                          </select>
                        </div>
                      </h1>
                      <p className={styles.demo}>
                        <h2>Product Demo</h2>
                        <img
                          src='https://cdn.builder.io/api/v1/image/assets/TEMP/defa8084f16f3d77ef8c8e959e418aeaac57dfe4712fb68ebdc3a61c0785f8f1?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1'
                          className={styles.gridimg} />
                        <b>Device 2 demo</b><br />
                        <span className={styles.video1}><b>2mins</b> <b>9983 view</b></span>
                      </p>
                      <h4>Educational Videos</h4>
                      <p className={styles.demo1}>
                        <div className={styles.galleryContainer}>
                          <div className={styles.imageWrapper}>
                            <img
                              src='https://cdn.builder.io/api/v1/image/assets/TEMP/defa8084f16f3d77ef8c8e959e418aeaac57dfe4712fb68ebdc3a61c0785f8f1?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1'
                              className={styles.gridimg}
                            />
                            <div className={styles.describe}>
                              <b>Device 2 demo</b><br />
                              <span className={styles.mini}><b>2 mins</b> <b>9983 views</b></span>
                            </div>
                          </div>

                          <div className={styles.imageWrapper}>
                            <img
                              src='https://cdn.builder.io/api/v1/image/assets/TEMP/defa8084f16f3d77ef8c8e959e418aeaac57dfe4712fb68ebdc3a61c0785f8f1?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1'
                              className={styles.gridimg}
                            />
                            <div className={styles.describe}>
                              <b>Device 2 demo</b><br />
                              <span className={styles.mini}><b>2 mins</b> <b>9983 views</b></span>
                            </div>
                          </div>
                          <div className={styles.imageWrapper}>
                            <img
                              src='https://cdn.builder.io/api/v1/image/assets/TEMP/defa8084f16f3d77ef8c8e959e418aeaac57dfe4712fb68ebdc3a61c0785f8f1?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1'
                              className={styles.gridimg}
                            />
                            <div className={styles.describe}>
                              <b>Device 2 demo</b><br />
                              <span className={styles.mini}><b>2 mins</b> <b>9983 views</b></span>
                            </div>
                          </div>
                          <div className={styles.imageWrapper}>
                            <img
                              src='https://cdn.builder.io/api/v1/image/assets/TEMP/defa8084f16f3d77ef8c8e959e418aeaac57dfe4712fb68ebdc3a61c0785f8f1?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1'
                              className={styles.gridimg}
                            />
                            <div className={styles.describe}>
                              <b>Device 2 demo</b><br />
                              <span className={styles.mini}><b>2 mins</b> <b>9983 views</b></span>
                            </div>
                          </div>
                          <div className={styles.imageWrapper}>
                            <img
                              src='https://cdn.builder.io/api/v1/image/assets/TEMP/defa8084f16f3d77ef8c8e959e418aeaac57dfe4712fb68ebdc3a61c0785f8f1?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1'
                              className={styles.gridimg}
                            />
                            <div className={styles.describe}>
                              <b>Device 2 demo</b><br />
                              <span className={styles.mini}><b>2 mins</b> <b>9983 views</b></span>
                            </div>
                          </div>
                          <div className={styles.imageWrapper}>
                            <img
                              src='https://cdn.builder.io/api/v1/image/assets/TEMP/defa8084f16f3d77ef8c8e959e418aeaac57dfe4712fb68ebdc3a61c0785f8f1?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1'
                              className={styles.gridimg}
                            />
                            <div className={styles.describe}>
                              <b>Device 2 demo</b><br />
                              <span className={styles.mini}><b>2 mins</b> <b>9983 views</b></span>
                            </div>
                          </div>
                        </div>
                      </p>
                    </div>
                  </div>
                )}

                <p onClick={openShotModal}><GoVideo /> &nbsp;Screenshot Video</p>
                <p><IoChevronForwardSharp /></p>

                {isShotModalOpen && (
                  <div className={styles.modal}>
                    <div className={styles.modalContent}>
                      <h1 className={styles.sc}>Screenshot Video
                        <div className={styles.filter}>
                          <select>
                            <option value='filter'>Filter</option>
                          </select>
                        </div>
                      </h1>
                      <p className={styles.demo}>
                        <h2>Product Demo</h2>
                        <img
                          src='https://cdn.builder.io/api/v1/image/assets/TEMP/defa8084f16f3d77ef8c8e959e418aeaac57dfe4712fb68ebdc3a61c0785f8f1?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1'
                          className={styles.gridimg} />
                        <b>Device 2 demo</b><br />
                        <span className={styles.video1}><b>2mins</b> <b>9983 view</b></span>
                      </p>
                      <h4>Educational Videos</h4>
                      <p className={styles.demo1}>
                        <div className={styles.galleryContainer}>
                          <div className={styles.imageWrapper}>
                            <img
                              src='https://cdn.builder.io/api/v1/image/assets/TEMP/defa8084f16f3d77ef8c8e959e418aeaac57dfe4712fb68ebdc3a61c0785f8f1?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1'
                              className={styles.gridimg}
                            />
                            <div className={styles.describe}>
                              <b>Device 2 demo</b><br />
                              <span className={styles.mini}><b>2 mins</b> <b>9983 views</b></span>
                            </div>
                          </div>

                          <div className={styles.imageWrapper}>
                            <img
                              src='https://cdn.builder.io/api/v1/image/assets/TEMP/defa8084f16f3d77ef8c8e959e418aeaac57dfe4712fb68ebdc3a61c0785f8f1?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1'
                              className={styles.gridimg}
                            />
                            <div className={styles.describe}>
                              <b>Device 2 demo</b><br />
                              <span className={styles.mini}><b>2 mins</b> <b>9983 views</b></span>
                            </div>
                          </div>
                          <div className={styles.imageWrapper}>
                            <img
                              src='https://cdn.builder.io/api/v1/image/assets/TEMP/defa8084f16f3d77ef8c8e959e418aeaac57dfe4712fb68ebdc3a61c0785f8f1?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1'
                              className={styles.gridimg}
                            />
                            <div className={styles.describe}>
                              <b>Device 2 demo</b><br />
                              <span className={styles.mini}><b>2 mins</b> <b>9983 views</b></span>
                            </div>
                          </div>
                          <div className={styles.imageWrapper}>
                            <img
                              src='https://cdn.builder.io/api/v1/image/assets/TEMP/defa8084f16f3d77ef8c8e959e418aeaac57dfe4712fb68ebdc3a61c0785f8f1?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1'
                              className={styles.gridimg}
                            />
                            <div className={styles.describe}>
                              <b>Device 2 demo</b><br />
                              <span className={styles.mini}><b>2 mins</b> <b>9983 views</b></span>
                            </div>
                          </div>
                          <div className={styles.imageWrapper}>
                            <img
                              src='https://cdn.builder.io/api/v1/image/assets/TEMP/defa8084f16f3d77ef8c8e959e418aeaac57dfe4712fb68ebdc3a61c0785f8f1?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1'
                              className={styles.gridimg}
                            />
                            <div className={styles.describe}>
                              <b>Device 2 demo</b><br />
                              <span className={styles.mini}><b>2 mins</b> <b>9983 views</b></span>
                            </div>
                          </div>
                          <div className={styles.imageWrapper}>
                            <img
                              src='https://cdn.builder.io/api/v1/image/assets/TEMP/defa8084f16f3d77ef8c8e959e418aeaac57dfe4712fb68ebdc3a61c0785f8f1?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1'
                              className={styles.gridimg}
                            />
                            <div className={styles.describe}>
                              <b>Device 2 demo</b><br />
                              <span className={styles.mini}><b>2 mins</b> <b>9983 views</b></span>
                            </div>
                          </div>
                        </div>
                      </p>
                    </div>
                  </div>
                )}

                <p onClick={openShareModal}><CiShare2 /> &nbsp;Share List</p>
                <p><IoChevronForwardSharp /></p>

                {isShareModalOpen && (
                  <div className={styles.modal}>
                    <div className={styles.modalContent}>
                      <p className={styles.privacy}>
                        <h1>Usage Terms</h1>
                        <div className={styles.policy}>
                          <p>General Policy for Ambicam <br />
                            <span className={styles.description}>
                              Turn on microphone permission to allow apps<br />
                              to record audio using device microphone
                            </span></p>

                          <p>Refund Policy<br />
                            <span className={styles.description}>
                              Turn on microphone permission to allow apps<br />
                              to record audio using device microphone
                            </span></p>

                          <p>Plateform Policy<br />
                            <span className={styles.description}>
                              Turn on microphone permission to allow apps<br />
                              to record audio using device microphone
                            </span></p>
                        </div>
                      </p>
                    </div>
                  </div>
                )}

                <p onClick={openHelpModal}><CiHeadphones /> &nbsp;Help</p>
                <p><IoChevronForwardSharp /></p>

                {isHelpModalOpen && (
                  <div className={styles.modal}>
                    <div className={styles.modalContent}>
                      <h1>Help</h1>
                      <p className={styles.help}>
                        <p>Technical Support</p>
                        <p><IoChevronForwardSharp /></p>
                        <p>Billing Support</p>
                        <p><IoChevronForwardSharp /></p>
                        <p>Others</p>
                        <p><IoChevronForwardSharp /></p>
                        <p>Chat with Support</p>
                        <p><IoChevronForwardSharp /></p>
                        <p>Device Upgrade</p>
                        <p><IoChevronForwardSharp /></p>
                        <p>Storage Support</p>
                        <p><IoChevronForwardSharp /></p>
                      </p>
                    </div>
                  </div>
                )}

                <p onClick={openAuthorityModal}><MdSystemUpdate /> &nbsp;Authority Management</p>
                <p><IoChevronForwardSharp /></p>

                {isAuthorityModalOpen && (
                  <div className={styles.modal}>
                    <div className={styles.modalContent}>
                      <h1>System Authority</h1>
                      <p className={styles.authority}>
                        <p>Microphone Permission <br />
                          <span className={styles.Minidescription}>Turn on microphone permission to allow apps
                            to record audio using device microphone
                          </span></p>
                        <p className={styles.forwardicon}>On<IoChevronForwardSharp /></p>
                        <p>Notification Permission<br />
                          <span className={styles.Minidescription}>If you turn off the notification it will now show any
                            event notification in the app notification tray
                          </span></p>
                        <p className={styles.forwardicon}>On<IoChevronForwardSharp /></p>
                        <p>Camera Permission<br />
                          <span className={styles.Minidescription}>If you turn off the notification it will now show any
                            event notification in the app notification tray
                          </span></p>
                        <p className={styles.forwardIcon}>Off<IoChevronForwardSharp /></p>
                        <p>Album Permission<br />
                          <span className={styles.Minidescription}>If you turn off the notification it will now show any
                            event notification in the app notification tray
                          </span></p>
                        <p className={styles.forwardIcon}>Off<IoChevronForwardSharp /></p>
                      </p>
                    </div>
                  </div>
                )}

                <p onClick={openPrivacyModal}><AiOutlineDollar /> &nbsp;Privacy Policy</p>
                <p><IoChevronForwardSharp /></p>
                {isPrivacyModalOpen && (
                  <div className={styles.modal}>
                    <div className={styles.modalContent}>
                      <p className={styles.privacy}>
                        <h1>Privacy Policy</h1>
                        <div className={styles.policy}>
                          <p>General Policy for Ambicam <br />
                            <span className={styles.description}>
                              Turn on microphone permission to allow apps<br />
                              to record audio using device microphone
                            </span></p>

                          <p>Refund Policy<br />
                            <span className={styles.description}>
                              Turn on microphone permission to allow apps<br />
                              to record audio using device microphone
                            </span></p>

                          <p>Plateform Policy<br />
                            <span className={styles.description}>
                              Turn on microphone permission to allow apps<br />
                              to record audio using device microphone
                            </span></p>
                        </div>
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileInfo;