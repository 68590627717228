import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from '../Settings.module.css';

function ISP_Setup({ deviceId, onSave }) {
  const [irMode, setIrMode] = useState('smart'); // Default IR mode
  const [isDigitalChecked, setIsDigitalChecked] = useState(false); // Digital width dynamic setting

  // Handle changes in IR mode dropdown
  const handleIrModeChange = async (event) => {
    const selectedValue = event?.target?.value;  // Ensure event is defined
    if (selectedValue) {
      setIrMode(selectedValue);

      const imageConfig = {
        irCutFilter: {
          irCutMode: selectedValue,
        },
      };

      try {
        const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/p2p/ir-setup`, imageConfig, {
          params: {
            deviceId: deviceId,
          },
          headers: {
            'Content-Type': 'application/json',
          },
        });

      } catch (error) {
        console.error('Error updating IR mode:', error.response ? error.response.data : error.message);
      }
    }
  };

  // Handle checkbox change for Digital Width Dynamic Setting (DWDR)
  const handleDigitalCheckboxChange = async (event) => {
    const checked = event?.target?.checked;  // Ensure event is defined
    if (checked !== undefined) {
      setIsDigitalChecked(checked);

      const dwdrConfig = {
        enable: checked,
      };

      try {
        const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/p2p/dwdr`, dwdrConfig, {
          params: {
            deviceId: deviceId,
          },
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Basic YWRtaW46', // Base64 encoding of "admin:"
          },
        });

      } catch (error) {
        console.error('Error updating DWDR:', error.response ? error.response.data : error.message);
      }
    }
  };

  // Pass the save function to the parent component
  useEffect(() => {
    if (onSave) {
      onSave(handleIrModeChange);
    }
  }, [onSave]);

  return (
    <div className={styles.notify}>
      {/* IR Mode selection */}
      <div className={styles.notificationItem}>
        <p>IR Mode</p>
        <div className={styles.selectContainer}>
          <select
            name="irMode"
            className={styles.selectInput}
            value={irMode}
            onChange={handleIrModeChange}  // Make sure the event is passed
          >
            <option value="irledmode">IR LED Mode</option>
            <option value="lightmode">Light Mode</option>
            <option value="smart">Smart Mode</option>
            <option value="daylight">Daylight Mode</option>
            <option value="night">Night Mode</option>
          </select>
        </div>
      </div>

      {/* Digital Width Dynamic Setting (DWDR) */}
      <div className={styles.checkboxContainer}>
        <p>Digital Width Dynamic Setting</p>
        <input
          type="checkbox"
          checked={isDigitalChecked}
          onChange={handleDigitalCheckboxChange}  // Make sure the event is passed
        />
      </div>
    </div>
  );
}

export default ISP_Setup;
