import React from 'react';
import styles from './Profile.module.css';

function ProfileHeader() {
  return (
    <header className={styles.profileHeader}>
      <h1 className={styles.profileTitle}>Profile</h1>
    </header>
  );
}

export default ProfileHeader;