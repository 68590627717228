'use client';

import {
  Badge,
  Box,
  Flex,
  Avatar,
  Text,
 
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
 
  useColorModeValue,
  Stack,

  HStack,
  VStack,
  IconButton,
  
  Link,
  Icon,
} from '@chakra-ui/react';
import adiance from '../assets/images/adiance.png'
// import adiance from '@img/adiance'
import NotificationDialog from './NotificationDialog';
import { useState ,useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { BsCameraVideo, BsCollectionPlay} from 'react-icons/bs';

import { FiChevronDown, FiSettings, FiBell, FiCreditCard, FiAlertOctagon } from 'react-icons/fi';
import {logout} from '../services/auth'
const NavLink = (props) => {
  const { children } = props;

  return (
    <Box
      as="a"
      px={2}
      py={1}
      rounded={'md'}
      _hover={{
        textDecoration: 'none',
        bg: useColorModeValue('gray.200', 'gray.700'),
      }}
      href={'#'}
    >
      {children}
    </Box>
  );
};

const LinkItems = [
  { name: 'Cameras', icon: BsCameraVideo ,path:'/dashboard'},
  { name: 'Multiple', icon:  BsCollectionPlay ,path: '/multiple'},
  { name: 'Settings', icon: FiSettings },
  { name: 'Alert', icon: FiAlertOctagon,path: '/alert' },
];

export default function Header() {
  const navigate = useNavigate();
  const handleLogout = async () => {
    // Perform logout logic here
    try {
      // Call the logout API
      const logoutResult = await logout();
  
      if (logoutResult.success) {
        // Remove any items from local storage or perform any other necessary cleanup
        localStorage.removeItem('isLoggedIn');
        localStorage.removeItem('token');

        // Redirect to the homepage after logout
        navigate('/');
      } else {
        // Handle logout error
        // You can display a message or take appropriate action
        console.error('Logout error:', logoutResult.message);
      }
    } catch (error) {
      // Handle unexpected errors, e.g., network issues
      console.error('Logout error:', error);
    }

  };

  const [isNotificationOpen, setNotificationOpen] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  
  const handleNotificationOpen = () => {
    setNotificationOpen(true);
  };
  
  const handleNotificationClose = () => {
    // Close the dialog
    setNotificationOpen(false);
  };
  
  const handleMarkAsRead = () => {
    // Reset the notification count
    setNotificationCount(0);
    handleNotificationClose();
  };
  const [email, setEmail] = useState('');


  useEffect(() => {
    // Retrieve user details from local storage
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    if (userDetails) {

      // alert(userDetails.langflag)
      setEmail(userDetails.email);
    }
  }, []);
  

  const handleCallSupport = () => {
    window.location.href = 'tel:+91 96876 72555';
  };
  const handleSendEmail = () => {
    const email = 'contact@vmukti.com'; 
    const subject = 'Support Request';
    const body = 'Hello, I need support with...'; 
  
    const mailtoUrl = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.open(mailtoUrl);
  };
  const handleOpenFAQ = () => {
    // Replace the URL with the desired FAQ page URL
    const faqUrl = 'https://ambicam.com/faq';
    window.open(faqUrl, '_blank');
  };
  
  const handleOpenPrivacyPolicy = () => {
    // Replace the URL with the desired FAQ page URL
    const faqUrl = 'https://home.arcisai.io/privacypolicy';
    window.open(faqUrl, '_blank');
  };

  const handleOpenTermsAndConditions = () => {
    // Replace the URL with the desired FAQ page URL
    const faqUrl = 'https://home.arcisai.io/termsandconditions';
    window.open(faqUrl, '_blank');
  };

  const handleOpenRefundAndCancellation = () => {
    // Replace the URL with the desired FAQ page URL
    const faqUrl = 'https://home.arcisai.io/refundandcancellation';
    window.open(faqUrl, '_blank');
  };

 // State for managing notifications
 const [notifications, setNotifications] = useState([]);
  
 // Function to handle incoming notifications from the WebSocket server
 const handleIncomingNotification = (notification) => {
   setNotifications((prevNotifications) => [...prevNotifications, notification]);
 };


  const SidebarContent = ({ onClose, ...rest }) => {
    return (
      <Box
        bg={'gray.100'}
        borderRight="1px"
        borderRightColor={useColorModeValue('gray.300', 'gray.700')}
        w={{ base: 'full', md: '4.5rem' }}
        pos="fixed"
        h="full"
        {...rest}
      >
        {LinkItems.map((link) => (
           
           <NavItem key={link.name} path={link.path} icon={link.icon} >
           <Link style={{ textDecoration: 'none' }} href={link.path}>{link.name}</Link>
         </NavItem>
         
        ))}
      </Box>
    );
  };
  const NavItem = ({ icon, children,path, ...rest }) => {
    return (
      <Box
        as="a"
        href={path}
        style={{ textDecoration: 'none' }}
        _focus={{ boxShadow: 'none' }}
      >
        <Flex
          direction="column"
          align="center"
          mt={3}
          mb={3}
          pt="2"
          pb="2"
          // pl="6"
          // pr="6"
          mx="1.5"
          borderRadius="lg"
          role="group"
          cursor="pointer"
          _hover={{
            bg: '#444444',
            color: 'red',
            backgroundSize: 'cover',
          }}
          {...rest}
        >
          {icon && (
            <Icon
              fontSize="20" 
              _groupHover={{
                color: 'red',
              }}
              as={icon}
            />
          )}
          <Text fontSize="12" >{children}</Text> 
        </Flex>
      </Box>
    );
  };
  return (
    <>
      <Box bg={useColorModeValue('gray.100', 'gray.900')} px={4} boxShadow={'base'} zIndex={9999}>


        <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
          <Box> <img style={{width:"50%"}} src={adiance} alt='adiance logo' /> </Box>

          <Flex alignItems={'center'}>
            <Stack direction={'row'} spacing={7}>
  
              <HStack spacing={{ base: '0', md: '6' }}>

              <Box position="relative" display="inline-block">
                <Badge
                  colorScheme="blue"
                  borderRadius="full"
                  px={2}
                  py={1}
                  fontSize="0.8em"
                  position="absolute"
                  top="-5px"
                  right="-5px"
                  zIndex={1}
                >
                {notificationCount}
                  </Badge>
                      <IconButton
                      aria-label="Notifications"
                      variant="ghost"
                      colorScheme="blue"
                      onClick={handleNotificationOpen}
                    >
                  <FiBell />
                </IconButton>
              </Box>

            <NotificationDialog
            isOpen={isNotificationOpen}
            onClose={handleNotificationClose}
            notificationCount={notificationCount}
            notifications={notifications}
            onMarkAsRead={handleMarkAsRead}
          />
        <Flex alignItems="center">
          <Menu>
            <MenuButton py={2} transition="all 0.3s" _focus={{ boxShadow: 'none' }}>
              <HStack>
                <Avatar
                  size="sm"
                  name={email}
                  bg="blue.200"
                />
                <VStack display={{ base: 'none', md: 'flex' }} alignItems="flex-start" spacing="1px" ml="2">
                  <Text fontSize="sm">{email}</Text>
                  <Text fontSize="xs" color="gray.600">
                    Adiance
                  </Text>
                </VStack>
                <Box display={{ base: 'none', md: 'flex' }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList bg="white" borderColor="gray.200">
              <MenuItem>Settings</MenuItem>

              <MenuItem onClick={handleCallSupport}>
                  Call Support
              </MenuItem>

              <MenuItem onClick={handleOpenFAQ}>                
                  FAQ
              </MenuItem>
              
              <MenuItem onClick={handleOpenPrivacyPolicy}>                
                  Privacy Policy
              </MenuItem>
              
              <MenuItem onClick={handleOpenTermsAndConditions}>                
                  Terms and Conditions
              </MenuItem>
              
              <MenuItem onClick={handleOpenRefundAndCancellation}>                
                  Refund And Cancellation
              </MenuItem>

              {/* <MenuItem onClick={handleSendEmail}>               
                  Email Support                
              </MenuItem>
               */}
              {/* <MenuItem>Billing</MenuItem> */}
              <MenuDivider />
              <MenuItem onClick={handleLogout} >Sign out</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>

            </Stack>
          </Flex>
        </Flex>
      </Box>
<SidebarContent mt={0.5}   display={{ base: 'none', md: 'block' }} />
    </>
  );
}
