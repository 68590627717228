// import React, { useState, useEffect } from 'react';
// import styles from '../AIEvents/Calender.module.css'; // Adjust the import path as needed
// import { getAiCameraList } from '../../services/aiCamera';
// import { getCustomerCameraList } from '../../services/getcamera';

// // Utility function to generate week dates
// const getWeekDates = (startDate) => {
//   const dates = [];
//   const dayMilliseconds = 24 * 60 * 60 * 1000;
//   const options = { day: '2-digit', month: 'short' }; // Format for date
//   const dayOptions = { weekday: 'long' }; // Format for day of the week
  
//   for (let i = 0; i < 7; i++) {
//     const date = new Date(startDate.getTime() + i * dayMilliseconds);
//     dates.push({
//       date: date.toLocaleDateString('en-US', options),
//       day: date.toLocaleDateString('en-US', dayOptions),
//       fullDate: date.toISOString().split('T')[0], // Store full date for comparison
//     });
//   }
//   return dates;
// };

// const Calendar = () => {
//   const [startDate, setStartDate] = useState(new Date()); // Default to the current week
//   const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]); // Select today's date by default
//   const [devices, setDevices] = useState([]); // State to store AI devices

//   // const userDetails = JSON.parse(localStorage.getItem('userDetails'));
//   const customerId = localStorage.getItem('userId');
//   let resultPerPage = 1000;
//   let page = 1;

//   useEffect(() => {
//     // Fetch AI devices on component mount
//     const fetchDevices = async () => {
//       try {
        // const response = await getCustomerCameraList(customerId, page, resultPerPage); // Fetch devices from API
        // if (response.success) {
        //   setDevices(response.cameras); // Assuming the API returns a 'devices' array
        // }
//       } catch (error) {
//         console.error('Error fetching AI devices:', error);
//       }
//     };

//     fetchDevices();
//   }, []);

//   const currentWeekDates = getWeekDates(startDate);

//   const goToPreviousWeek = () => {
//     const newStartDate = new Date(startDate.getTime() - 7 * 24 * 60 * 60 * 1000);
//     setStartDate(newStartDate);
//   };

//   const goToNextWeek = () => {
//     const newStartDate = new Date(startDate.getTime() + 7 * 24 * 60 * 60 * 1000);
//     setStartDate(newStartDate);
//   };

//   const handleDateClick = (date) => {
//     setSelectedDate(date);
//   };

//   return (
//     <div className={styles.calendarWrapper}>
//       <div className={styles.calendarControls}>
//         <select id="deviceSelect" className={styles.select}>
//           <option value="">All Devices</option>
//           {devices.map((device) => (
//             <option key={device.deviceid} value={device.deviceid}>
//               {device.deviceid}
//             </option>
//           ))}
//         </select>
//         <button className={styles.prevWeek} onClick={goToPreviousWeek}>Previous Week</button>
//         <button className={styles.nextWeek} onClick={goToNextWeek}>Next Week</button>
//       </div>
//       <div className={styles.dateList}>
//         {currentWeekDates.map((date, index) => (
//           <div
//             key={index}
//             className={`${styles.dateItem} ${date.fullDate === selectedDate ? styles.selected : ''}`}
//             onClick={() => handleDateClick(date.fullDate)}
//           >
//             <div className={styles.date}>{date.date}</div>
//             <div className={styles.day}>{date.day}</div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Calendar;


import React, { useEffect, useState } from 'react';
import styles from '../AIEvents/Calender.module.css'; // Adjust the import path as needed
import { getCustomerCameraList } from '../../services/getcamera';

// Utility function to generate week dates
const getWeekDates = (startDate) => {
  const dates = [];
  const dayMilliseconds = 24 * 60 * 60 * 1000;
  const options = { day: '2-digit', month: 'short' };
  const dayOptions = { weekday: 'long' };

  for (let i = 0; i < 7; i++) {
    const date = new Date(startDate.getTime() + i * dayMilliseconds);
    dates.push({
      date: date.toLocaleDateString('en-IN', options),
      day: date.toLocaleDateString('en-IN', dayOptions),
      fullDate: new Date(date.getTime() - date.getTimezoneOffset() * 60000)
        .toISOString()
        .split('T')[0], // Adjust full date to local timezone
    });
  }
  return dates;
};

const formatDateToISO = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

// Utility function to get current IST date
const getCurrentISTDate = () => {
  const offset = 5.5; // IST is UTC+5:30
  const localDate = new Date();
  const utcDate = new Date(localDate.getTime() + (localDate.getTimezoneOffset() * 60000));
  const istDate = new Date(utcDate.getTime() + (offset * 3600000));
  return formatDateToISO(istDate);
};


const fetchData = async (page, updateOnlyStatus = false) => {
  // const userDetails = localStorage.getItem('userDetails');
  const customerId = localStorage.getItem("userId")

  let resultPerPage = 9;
  if (window.innerWidth > 1645) {
    resultPerPage = 12; // Set to 12 if the screen width is greater than 1645px
  }

  try {
    // Fetch status data
    const statusResponse = await fetch('https://adiance-portal-backend-7d9tj.ondigitalocean.app/status');
    const statusData = await statusResponse.json();

    if (!updateOnlyStatus) {
      // Fetch camera list data
      const result = await getCustomerCameraList(customerId, page, resultPerPage);
      if (result.success) {
        // Merge the status data with camera list data
        const updatedCameraList = result.cameras.map(camera => {
          const matchingStatus = statusData.proxies.find(proxy => 
            proxy.name.trim().toLowerCase() === camera.deviceid.trim().toLowerCase()
          );
          return {
            ...camera,
            status: matchingStatus && matchingStatus.status === 'online' ? 'connected' : 'disconnected' // Add status field or default to 'unknown'
          };
        });
        return updatedCameraList;
      }
    }
  } catch (error) {
    console.error('Error fetching camera list:', error);
  }
  return [];
};


const Calendar = ({ onDeviceSelect ,onDateSelect  }) => {
  
  const [startDate, setStartDate] = useState(new Date()); // Default to the current week
  const [selectedDate, setSelectedDate] = useState(() => getCurrentISTDate());
 // State for selected date 
  const [devices, setDevices] = useState([]);
  const currentWeekDates = getWeekDates(startDate);

  const goToPreviousWeek = () => {
    const newStartDate = new Date(startDate.getTime() - 7 * 24 * 60 * 60 * 1000);
    setStartDate(newStartDate);
  };

  const goToNextWeek = () => {
    const newStartDate = new Date(startDate.getTime() + 7 * 24 * 60 * 60 * 1000);
    setStartDate(newStartDate);
  };

  const handleDateClick = (dateString) => {
    const date = new Date(dateString); // Convert the date string to a Date object
    const adjustedDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000)
      .toISOString()
      .split('T')[0];
    setSelectedDate(adjustedDate);
    onDateSelect(adjustedDate);
  };
  
  useEffect(() => {
    const loadDevices = async () => {
      try {
        const data = await fetchData(1);

        setDevices(data);
      } catch (error) {
        console.error('Error fetching devices:', error);
      }
    };
  
    loadDevices();
  }, []); 
  
  const handleDeviceSelect = (event) => {
    const selectedDeviceId = event.target.value;
    const selectedDevice = devices.find(device => device.deviceid === selectedDeviceId);
    onDeviceSelect(selectedDevice); 
  };

  return (
    <div className={styles.calendarWrapper}>
      <div className={styles.calendarControls}>
        <select id="deviceSelect" className={styles.select} onChange={handleDeviceSelect}>
        {devices.map(device => (
          <option>{device.deviceid}</option>
        ))}
        </select>
        <button className={styles.prevWeek} onClick={goToPreviousWeek}>Previous Week</button>
        <button className={styles.nextWeek} onClick={goToNextWeek}>Next Week</button>
      </div>
      <div className={styles.dateList}>
        {currentWeekDates.map((date, index) => (
          <div
            key={index}
            className={`${styles.dateItem} ${date.fullDate === selectedDate ? styles.selected : ''}`}
            onClick={() => handleDateClick(date.fullDate)}
          >
            <div className={styles.date}>{date.date}</div>
            <div className={styles.day}>{date.day}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Calendar;