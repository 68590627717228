import React from 'react';
import ProfileInfo from './ProfileInfo';
import AccountSecurity from './AccountSecurity';
import styles from './ProfilePage.module.css';

function ProfilePage() {
  return (
    <div className={styles.profileDesktop}>
      <main className={styles.mainContent}>
        <section className={styles.profileSection}>
          <ProfileInfo />
          <AccountSecurity />
        </section>
      </main>
    </div>
  );
}

export default ProfilePage;