import React, { useState, useRef, useEffect } from 'react';
import {
  Flex,
  Box,
  Text,
  FormControl,
  FormLabel,
  Checkbox,
  Select,
  Button,
  VStack,
  Input,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb
} from '@chakra-ui/react';

import axios from 'axios';


const PrivacyMask = ({deviceId}) => {
    const handlePrivacyMask = async () => {

      }
  
  return (

    <Flex direction="column" padding="10px" align="start" gap={4}>
            <Box>
              <Checkbox>Enable Privacy Mask</Checkbox>
            </Box>
            <Box>
              <Select placeholder="Color">
                <option value="5aaf5a">green</option>
                <option value="000000">white</option>
                <option value="006ecf">blue</option>
              </Select>
            </Box>
            <Box>
              <Button colorScheme="blue" onClick={handlePrivacyMask}>Apply</Button>
            </Box>
          </Flex>

    
);
};

export default PrivacyMask;