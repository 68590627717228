import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import axios from "axios";

import styles from "../Settings.module.css";
import { Radio, RadioGroup, Stack } from "@chakra-ui/react";
function Time({ deviceId }) {
  const [timeSettings, setTimeSettings] = useState("");
  const [ntpServer, setntpServer] = useState("");
  const [currentDateTime, setCurrentDateTime] = useState("");
  const [timeZoneOffset, setTimeZoneOffset] = useState("");
  const [timeSync, setTimeSync] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/p2p/time-settings`,
          {
            params: {
              deviceid: deviceId,
            },
          }
        );

        const apiDateTime = response.data; // Assuming the API response is a single date-time string
        const formattedDateTime = dayjs(apiDateTime).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        setTimeSettings(formattedDateTime);
        const timeZoneOffset = apiDateTime.slice(-6);
        setTimeZoneOffset(timeZoneOffset);
      } catch (error) {
        setError(error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const updateDateTime = () => {
      const now = dayjs();
      setCurrentDateTime(now.format("YYYY-MM-DD HH:mm:ss"));
    };

    updateDateTime();
    const intervalId = setInterval(updateDateTime, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const handleNtpSettings = async () => {
    const ntpServerDomain = "";
    const ntpcheck = { timeSync, ntpServerDomain };
    const response = await axios.put(
      `${process.env.REACT_APP_BASE_URL}/p2p/ntpTime-settings`,
      ntpcheck,
      {
        params: {
          deviceid: deviceId,
        },
      }
    );

  };
  return (
    <div className={styles.notify}>
      <div style={{ display: "flex" }}>
        <div>
          <div
            className={styles.checkboxContainer}
            style={{ marginBottom: "16px", color: "#7F56D9" }}
          >
            <label style={{ fontSize: "18px", fontWeight: "bold" }}>
              Time Setup
            </label>
          </div>

          <div style={{ marginBottom: "12px" , alignItems: "center" }}>
            <label style={{ flex: "0 0 150px", marginRight: "16px", fontWeight: "bold" }}>Device Time</label>
            <input
              type="text"
              value={timeSettings}
              disabled
              style={{
                border: "1px solid #ccc",
                padding: "8px",
                borderRadius: "4px",
                fontSize: "14px"
              }}
            />
          </div>
          <div style={{ marginBottom: "12px" , alignItems: "center" }}>
          <label style={{  marginRight: "16px", fontWeight: "bold" }}>Computer Date and Time</label>
          <input
            type="text"
            value={currentDateTime}
            disabled
            style={{
              border: "1px solid #ccc",
              padding: "8px",
              borderRadius: "4px",
              fontSize: "14px"
            }}
            />
            </div>
          <div style={{ marginBottom: "16px", marginTop: "16px" }}>
            <label style={{ flex: "0 0 150px", marginRight: "16px", fontWeight: "bold" }}>Time Zone</label>
            <select value={timeZoneOffset}  style={{
      padding: "8px 12px",
      border: "1px solid #ccc",
      borderRadius: "4px",
      fontSize: "14px",
      color: "#333",
      backgroundColor: "#fff",
      // appearance: "none", // Remove the default arrow style
      // boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)", // Subtle shadow for depth
    }}>
              {[
                "-12:00",
                "-11:00",
                "-10:00",
                "-09:00",
                "-08:00",
                "-07:00",
                "-06:00",
                "-05:00",
                "-04:00",
                "-03:00",
                "-02:00",
                "-01:00",
                "+00:00",
                "+01:00",
                "+02:00",
                "+03:00",
                "+04:00",
                "+05:00",
                "+05:30",
                "+06:00",
                "+07:00",
                "+08:00",
                "+09:00",
                "+10:00",
                "+11:00",
                "+12:00",
              ].map((tz) => (
                <option key={tz} value={tz}>{`GMT ${tz}`}</option>
              ))}
            </select>
          </div>

         <div style={{ marginBottom: "16px", marginTop: "16px" }}>  
          <label
           style={{ flex: "0 0 150px", marginRight: "16px", fontWeight: "bold", color:'#7F56D9' }}
          >
              Local Time Sync
              
          </label>
          <div style={{ marginBottom: "16px", marginTop: "16px" }}>
            <label  style={{ flex: "0 0 150px", marginRight: "16px", fontWeight: "bold" }}
          >Computer Date and Time</label>
            <input
              type="text"
              value={currentDateTime}
              disabled
              style={{
                border: "1px solid #ccc",
                padding: "8px",
                borderRadius: "4px",
                fontSize: "14px"
              }}
            />
            </div>
            </div>
          <button
           style={{
            backgroundColor: "#88ffcc",
            color: "black",
            padding: "8px 12px",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
            fontWeight: "bold"
          }}
          >
            Sync
          </button>
        </div>

        <div style={{ marginLeft: "16px" }}>
          <label  style={{ flex: "0 0 150px", marginRight: "16px", fontWeight: "bold",color:'#7f56D9' }}>
            NTP Time Sync
          </label>
          <div style={{ marginBottom: "16px", marginTop: "16px" }}>
            <RadioGroup
              mb={4}
              value={ntpServer.enabled == true ? "close" : "open"}
              onChange={(e) => setTimeSync(e.target.value)}
              style={{
                padding: "8px",
                borderRadius: "4px",
                fontSize: "14px"
              }}
            >
              <Stack direction="row">
                <Radio value="open">Open</Radio>
                <Radio value="close">Close</Radio>
              </Stack>
            </RadioGroup>
          </div>

          <div style={{ marginBottom: "12px", display: "flex", alignItems: "center" }}>
            <label style={{ flex: "0 0 150px", marginRight: "16px", fontWeight: "bold" }}>NTP Server</label>
            <input
              type="text"
              placeholder="Enter NTP server address"
              value={ntpServer.ntpServerDomain}
              style={{
                border: "1px solid #ccc",
                padding: "8px",
                borderRadius: "4px",
                fontSize: "14px"
              }}            />
          </div>
          <button
style={{
  backgroundColor: "#7F56D9" ,
  color: "white",
  padding: "8px 12px",
  border: "none",
  borderRadius: "4px",
  cursor: "pointer",
  fontWeight: "bold"
}}
            onClick={handleNtpSettings}
          >
            Save Time Configuration
          </button>
        </div>
      </div>
    </div>
  );
}

export default Time;
