import React from 'react';
import styles from './AccountSecurity.module.css';

function AccountSecurity() {
  const securityItems = [
    { label: "Change Password", action: "change the account password" },
    { label: "Login log", action: "View the login history" },
    { label: "Get help", action: "" },
  ];
  const securityitems = [
    { label: "Line Push Binding", action: "bind to the Line app for alarm messages." },
    { label: "Tmall Sprite Authorization", action: "authorize" }
  ];

  return (
    <section className={styles.accountSecurity}>
      <div className={styles.securityColumn}>
        <h2 className={styles.sectionTitle}>Account security</h2>
        {securityItems.map((item, index) => (
          <div key={index} className={styles.securityRow}>
            <p className={styles.securityLabel}>{item.label}</p>
            {item.action && <p className={styles.securityAction}>{item.action}</p>}
          </div>
        ))}

        <h3 className={styles.subsectionTitle}>Binding/Authorization Section</h3>
        <div className={styles.securityColumn}>
          {securityitems.map((item, index) => (
            <div key={index} className={styles.securityRow}>
              <p className={styles.securityLabel}>{item.label}</p>
              {item.action && <p className={styles.securityAction}>{item.action}</p>}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default AccountSecurity;
