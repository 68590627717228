import AWS from 'aws-sdk';

// Configure AWS SDK
const spacesEndpoint = new AWS.Endpoint('https://blr1.digitaloceanspaces.com'); // Replace with your region endpoint
const s3 = new AWS.S3({
  endpoint: spacesEndpoint,
  accessKeyId: 'DO00G3B63HQWDRVZ83U8', // Replace with your access key
  secretAccessKey: 'acISaxTCW2SoYzl+NMZonH5JluxxUG51Sk/h0PjqzyQ', // Replace with your secret key
});

export const getSignedUrl = async (key) => {
  const params = {
    Bucket: 'torque-ai', // Replace with your bucket name
    Key: key,
  };

  try {
    const url = await s3.getSignedUrlPromise('getObject', params);
    return url;
  } catch (err) {
    console.error('Error getting signed URL:', err);
    return null;
  }
};
