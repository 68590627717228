import { Box, Button, Divider, Flex, FormControl, FormLabel, Heading, Input, Radio, RadioGroup, Select, Stack } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import dayjs from 'dayjs';
import axios from "axios";

const TimeSetup = ({deviceId}) => {

    const[timeSettings, setTimeSettings] = useState('')
    const [ntpServer, setntpServer] = useState('')
    const [currentDateTime, setCurrentDateTime] = useState('')
    const [timeZoneOffset, setTimeZoneOffset] = useState('');
    const [timeSync, setTimeSync] = useState('')
    const [error, setError] = useState(null);


    useEffect(() => {
      const fetchData = async () => {

        try {
          const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/p2p/time-settings`, {
            params: {
              deviceid: deviceId
            }
          });

          const apiDateTime = response.data; // Assuming the API response is a single date-time string
          const formattedDateTime = dayjs(apiDateTime).format('YYYY-MM-DD HH:mm:ss');
          setTimeSettings(formattedDateTime);
          const timeZoneOffset = apiDateTime.slice(-6);
          setTimeZoneOffset(timeZoneOffset);
        } catch (error) {
          setError(error);
        }
      };
      fetchData();
    }, []);

    useEffect(() => {
      const updateDateTime = () => {
        const now = dayjs();
        setCurrentDateTime(now.format('YYYY-MM-DD HH:mm:ss'));
      };
  
      updateDateTime();
      const intervalId = setInterval(updateDateTime, 1000);
  
      return () => clearInterval(intervalId);
    }, []);

    const handleNtpSettings = async () => {
      const ntpServerDomain = ''
      const ntpcheck={timeSync,ntpServerDomain}
      const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/p2p/ntpTime-settings`, ntpcheck,{
        params: {
          deviceid: deviceId
        }
      })

    }

    return(
      <Flex>
      <Box>
        <Heading as="h3" size="md" mb={4} color="blue.500">
          Time Setup
        </Heading>
        <Divider my={2} />
        <FormControl mb={4}>
          <FormLabel>Device Time</FormLabel>
          <Input type="text" value={timeSettings} disabled />
        </FormControl>
        <FormLabel>Computer Date and Time</FormLabel>
<Input type="text" value={currentDateTime} disabled />
<FormControl mb={4} mt={4}>
  <FormLabel>Time Zone</FormLabel>
  <Select value={timeZoneOffset}>
    {[
      "-12:00", "-11:00", "-10:00", "-09:00", "-08:00", "-07:00",
      "-06:00", "-05:00", "-04:00", "-03:00", "-02:00", "-01:00",
      "+00:00", "+01:00", "+02:00", "+03:00", "+04:00", "+05:00",
      "+05:30", "+06:00", "+07:00", "+08:00", "+09:00", "+10:00", "+11:00",
      "+12:00"
    ].map((tz) => (
      <option key={tz} value={tz}>{`GMT ${tz}`}</option>
    ))}
  </Select>
</FormControl>
        <Heading as="h4" size="sm" mt={4} mb={2} color="blue.500">
          Local Time Sync
        </Heading>
        <FormControl mb={4}>
          <FormLabel>Computer Date and Time</FormLabel>
          <Input type="text" value={currentDateTime} disabled />
        </FormControl>
        <Button colorScheme="blue" mb={4}>
          Sync
        </Button>
        </Box>
        <Box ml={4}>
        <Heading as="h4" size="sm"  mb={2} color="blue.500">
          NTP Time Sync
        </Heading>
        <RadioGroup mb={4} value={ntpServer.enabled == true ? "close" : "open"} onChange={(e) => setTimeSync(e.target.value)}>
          <Stack direction="row">
            <Radio value="open">Open</Radio>
            <Radio value="close">Close</Radio>
          </Stack>
        </RadioGroup>
          <FormControl mb={4}>
            <FormLabel>NTP Server</FormLabel>
            <Input type="text" placeholder="Enter NTP server address" value={ntpServer.ntpServerDomain} />
          </FormControl>
          <Button colorScheme="blue" onClick={handleNtpSettings}>
            Save Time Configuration
          </Button>
      </Box>
      </Flex>  
    )
}

export default TimeSetup;