import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from '../Settings.module.css';

function OSD_Setup({ deviceId, onSave }) {
  const [data, setData] = useState({
    datetimeOverlay: {
      enabled: false,
      dateFormat: "",
      timeFormat: "",
      regionX: "",
      regionY: "",
      displayWeek: false,
    },
    channelNameOverlay: {
      enabled: false,
      regionX: "",
      regionY: "",
    },
  });
  const [dateFormat, setDateFormat] = useState("");
  const [timeFormat, setTimeFormat] = useState("");
  const [datetimeOverlayEnabled, setDatetimeOverlayEnabled] = useState(false);
  const [channelNameOverlayEnabled, setChannelNameOverlayEnabled] =
    useState(false);

  useEffect(() => {
    // Fetch data from API
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/p2p/osd-settings`,
          {
            params: { deviceId: deviceId },
          }
        );
        setData(response.data);
        setDateFormat(response.data.datetimeOverlay.dateFormat);
        setTimeFormat(response.data.datetimeOverlay.timeFormat);
        setDatetimeOverlayEnabled(response.data.datetimeOverlay.enabled);
        setChannelNameOverlayEnabled(response.data.channelNameOverlay.enabled);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [deviceId]);

  const handleDateFormatChange = (e) => setDateFormat(e.target.value);
  const handleTimeFormatChange = (e) => setTimeFormat(e.target.value);
  const handleDatetimeOverlayEnabledChange = (e) =>
    setDatetimeOverlayEnabled(e.target.checked);
  const handleChannelNameOverlayEnabledChange = (e) =>
    setChannelNameOverlayEnabled(e.target.checked);

  const handleOSDClick = async () => {
    const parsedTimeFormat =
      parseInt(timeFormat, 10) || parseInt(data.datetimeOverlay.timeFormat, 10);

    const requestBody = {
      datetimeOverlay: {
        enabled: datetimeOverlayEnabled,
        dateFormat: dateFormat || data.datetimeOverlay.dateFormat,
        timeFormat: parsedTimeFormat,
        regionX: data.datetimeOverlay.regionX,
        regionY: data.datetimeOverlay.regionY,
        displayWeek: data.datetimeOverlay.displayWeek,
      },
      channelNameOverlay: {
        enabled: channelNameOverlayEnabled,
        regionX: data.channelNameOverlay.regionX,
        regionY: data.channelNameOverlay.regionY,
      },
      deviceIDOverlay: { enabled: false, regionX: 0, regionY: 0 },
    };

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/p2p/osd-settings`,
        requestBody,
        {
          params: { deviceId: deviceId },
        }
      );

    } catch (error) {
      console.error("Error making API call:", error);
    }
  };

  return (
    <div className={styles.notify}>
      {/* IR Mode selection */}
      <div className={styles.checkboxContainer}>
        <div>
          {" "}
          <label className={styles.overlayLabel}>
            <input
              type="checkbox"
              checked={datetimeOverlayEnabled}
              onChange={handleDatetimeOverlayEnabledChange}
            />
            Date Time Overlay
          </label>
        </div>
        <div>
          <label className={styles.overlayLabel}>
            <input
              type="checkbox"
              checked={datetimeOverlayEnabled}
              onChange={handleDatetimeOverlayEnabledChange}
            />
            Channel Name Overlay
          </label>
        </div>
      </div>

      <div>
        {" "}
        <select value={dateFormat} onChange={handleDateFormatChange}>
          <option value="DD/MM/YYYY">DD-MM-YYYY</option>
          <option value="MM/DD/YYYY">MM-DD-YYYY</option>
          <option value="YYYY/MM/DD">YYYY-MM-DD</option>
        </select>
      </div>

      <div>
        <select value={timeFormat} onChange={handleTimeFormatChange}>
          <option value="12">12-hour</option>
          <option value="24">24-hour</option>
        </select>
      </div>
      <div>
        <checkbox isChecked={data.datetimeOverlay.displayWeek}>
          Display Week
        </checkbox>
      </div>
      <div>
        <button onClick={handleOSDClick} style={{ color: "blue" }}>
          Apply
        </button>
      </div>
    </div>
  );
}

export default OSD_Setup;
