import React from 'react';
import styles from './Info.module.css';

function Info({ device, onClose }) {

  return (
   
    <div className={styles.modal}>
    <div className={styles.modalContent}>
      <h1 style={{ color: "black" }}>Info:</h1>
      <br/>
      <p style={{ color: "black", fontWeight: "lighter", marginTop: "-10px" }}>
          <form className={styles.form}>
                <div className={styles.formGroup}>
                  <label className={styles.label} htmlFor="deviceDescription">Data Usage:</label>
                  <p className={styles.value}>{(device.todayTrafficOut / (1024 ** 3)).toFixed(2)} GB</p>
                </div>

                <div className={styles.formGroup}>
                  <label className={styles.label} htmlFor="currentStreaming">Current Streaming:</label>
                  <p className={styles.value}>{device.current_streaming + 1}</p>
                </div>

                <div className={styles.formGroup}>
                  <label className={styles.label} htmlFor="lastStartTime">Last Start Time:</label>
                  <p className={styles.value}>{device.lastStartTime}</p>
                </div>

                <div className={styles.formGroup}>
                  <label className={styles.label} htmlFor="lastCloseTime">Last Close Time:</label>
                  <p className={styles.value}>{device.lastCloseTime}</p>
                </div>

                <div className={styles.buttonGroup}>
                  <button type="button" className={styles.cancelButton} onClick={onClose}>Close</button>
                </div>
          </form>
      </p>
    </div>
  </div>
  );
}

export default Info;