import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from '../Settings.module.css';

function Video_Setup({ deviceId, onSave }) {
  const [data, setData] = useState({
    microphoneType: '',
    codecType: '',
    encodeType: '',
    stream: '',
    resolution: '',
    bitRateControlType: '',
    channelName: '',
    constantBitRate: '',
    frameRate: '',
  });
  
  const [error, setError] = useState(null);

  // Fetch the current video settings when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/p2p/get-videoSettings`, {
          params: { deviceid: deviceId },
        });

        setData(response.data);
      } catch (error) {
        setError(error);
      }
    };

    fetchData();
  }, [deviceId]);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Function to save the settings
  const handleMediaContent = () => {
    const config = {
      headers: {
        Authorization: 'Basic ' + btoa('admin:'),
        'Content-Type': 'application/json',
      },
    };

    axios
      .put(`${process.env.REACT_APP_BASE_URL}/p2p/video-setup`, data, config)
      .then((response) => {

      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  // Pass the save function to the parent component
  useEffect(() => {
    onSave(handleMediaContent);
  }, [onSave, data]);

  return (
    <div className={styles.notify}>
      <div className={styles.notificationItem}>
        <p>Microphone Type</p>
        <div className={styles.selectContainer}>
          <select
            name="microphoneType"
            className={styles.selectInput}
            value={data.microphoneType}
            onChange={handleInputChange}
          >
            <option value="Active Pickup">Active Pickup</option>
            <option value="Passive Mic">Passive Mic</option>
          </select>
        </div>
      </div>

      <div className={styles.notificationItem}>
        <p>Profile</p>
        <div className={styles.selectContainer}>
          <select
            name="codecType"
            className={styles.selectInput}
            value={data.codecType}
            onChange={handleInputChange}
          >
            <option value="H.264">H.264</option>
            <option value="H.265">H.265</option>
            <option value="H.264+">H.264+</option>
            <option value="H.265+">H.265+</option>
          </select>
        </div>
      </div>

      <div className={styles.notificationItem}>
        <p>Encode Type</p>
        <div className={styles.selectContainer}>
          <select
            name="encodeType"
            className={styles.selectInput}
            value={data.encodeType}
            onChange={handleInputChange}
          >
            <option value="video">video</option>
            <option value="audio&video">audio&video</option>
          </select>
        </div>
      </div>

      <div className={styles.notificationItem}>
        <p>Stream</p>
        <div className={styles.selectContainer}>
          <select
            name="stream"
            className={styles.selectInput}
            value={data.stream}
            onChange={handleInputChange}
          >
            <option value="main stream">main stream</option>
            <option value="substream1">substream1</option>
          </select>
        </div>
      </div>

      <div className={styles.notificationItem}>
        <p>Resolution</p>
        <div className={styles.selectContainer}>
          <select
            name="resolution"
            className={styles.selectInput}
            value={data.resolution}
            onChange={handleInputChange}
          >
            <option value="2304x1296">2304x1296</option>
            <option value="1920x1080">1920x1080</option>
            <option value="1280x720">1280x720</option>
          </select>
        </div>
      </div>

      <div className={styles.notificationItem}>
        <p>Bitrate Control Type</p>
        <div className={styles.selectContainer}>
          <select
            name="bitRateControlType"
            className={styles.selectInput}
            value={data.bitRateControlType}
            onChange={handleInputChange}
          >
            <option value="vbr">VBR</option>
            <option value="cbr">CBR</option>
          </select>
        </div>
      </div>

      <div className={styles.notificationItem}>
        <p>Channel Name</p>
        <div className={styles.inputContainer}>
          <input
            type="text"
            name="channelName"
            value={data.channelName}
            onChange={handleInputChange}
            className={styles.inputField}
          />
        </div>
      </div>

      <div className={styles.notificationItem}>
        <p>BPS</p>
        <div className={styles.inputContainer}>
          <input
            type="text"
            name="constantBitRate"
            value={data.constantBitRate}
            onChange={handleInputChange}
            className={styles.inputField}
          />
        </div>
      </div>

      <div className={styles.notificationItem}>
        <p>FPS</p>
        <div className={styles.inputContainer}>
          <input
            type="text"
            name="frameRate"
            value={data.frameRate}
            onChange={handleInputChange}
            className={styles.inputField}
          />
        </div>
      </div>
    </div>
  );
}

export default Video_Setup;
