import React from 'react';
import styles from './Profile.module.css';
import ProfileHeader from './ProfileHeader';
import ContactInfo from './ContactInfo';
// import BindSection from './BindSection';

function Profile() {
  return (
    <main className={styles.profileContainer}>
      <ProfileHeader />
      <section className={styles.contentSection}>
        <h2 className={styles.sectionTitle}>List of events triggered by cameras</h2>
        <div className={styles.infoWrapper}>
          <ContactInfo />
          {/* <BindSection /> */}
        </div>
      </section>
    </main>
  );
}

export default Profile;