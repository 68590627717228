import React from 'react';
import styles from './SignUpPage.module.css';
function Header() {
    return (
      <header className={styles.header}>
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/9cadae3544d2e6a3ad6aafa7ad59b08c3216909ee850a29208e6a388b548619a?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1" alt="Company Logo" className={styles.logo} />
        <div className={styles.decorativeElement}></div>
      </header>
    );
  }
  
  export default Header;