import { Box, Select } from '@chakra-ui/react';
import axios from 'axios';
import React, { useState } from 'react';
import { Joystick } from 'react-joystick-component';

const CameraPTZ = ({ deviceId }) => {
    const [selectedSpeed, setSelectedSpeed] = useState('2')
  const handleMove = (event) => {
    const direction = event.direction;
    handleJoystickClick(direction);
  };

  const handleJoystickClick = async (direction) => {
    const username = 'admin';
    const password = ''; // Ensure you replace this with the actual password
    const moveDuration = 1000; // Duration to move the camera in milliseconds (1 second in this example)
  
    const step = 0; // Adjust as needed
    const presetNUM = 1; // Adjust as needed
  
    let act;
    switch (direction) {
      case 'LEFT':
        act = 'left';
        break;
      case 'RIGHT':
        act = 'right';
        break;
      case 'FORWARD':
        act = 'up';
        break;
      case 'BACKWARD':
        act = 'down';
        break;
      default:
        console.error('Invalid direction');
        return;
    }

    const ptzParams = {
      '-step': step,
      '-act': act,
      '-speed': selectedSpeed,
      '-presetNUM': presetNUM,
      deviceId: `${deviceId}.torqueverse.dev` // Replace with actual deviceId
    };
  
    const authHeader = 'Basic ' + btoa(`${username}:${password}`);
  
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/p2p/ptz`, ptzParams, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': authHeader
        }
      });

  
      await new Promise(resolve => setTimeout(resolve, moveDuration));
  
      const stopParams = {
        '-step': step,
        '-act': 'stop',
        '-speed': selectedSpeed,
        '-presetNUM': presetNUM,
        deviceId: `${deviceId}.torqueverse.dev`
      };
  
      const stopResponse = await axios.post(`${process.env.REACT_APP_BASE_URL}/p2p/ptz`, stopParams, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': authHeader
        }
      });

  
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
    }
  };

  return (
    <div>
     
      <Box
display="flex"
flexDirection="column"
justifyContent="flex-end"
position="absolute"
top="65%"
left="7%"
transform="translate(-50%, -50%)"
zIndex={1}
>
<Joystick
        size={100}
        baseColor="#faf6ff"
        stickColor="#5a5a5a"
        move={handleMove}
        stop={() => handleJoystickClick('stop')}
      />
<Select
  mt={10}
  justifyContent="space-between"
  ml={0}
  width="110px"
  backgroundColor="#faf6ff"
  color="black"
  border="2px solid rgba(29, 30, 34, 0.7)"
  onChange={(e) => setSelectedSpeed(e.target.value)}
>
  <option value="1" style={{ color: 'black' }}>
    Speed 1
  </option>
  <option value="2" style={{ color: 'black' }}>
    Speed 2
  </option>
  <option value="3" style={{ color: 'black' }}>
    Speed 3
  </option>
  <option value="4" style={{ color: 'black' }}>
    Speed 4
  </option>
  <option value="5" style={{ color: 'black' }}>
    Speed 5
  </option>
  <option value="6" style={{ color: 'black' }}>
    Speed 6
  </option>
</Select>
</Box>
    </div>
  );
};

export default CameraPTZ;