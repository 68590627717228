import React from "react";
import axios from "axios";

import styles from "../Settings.module.css";

function RTMPSetup({ deviceId }) {
  return (
    <div className={styles.notify}>
     <div
        className={styles.checkboxContainer}
        style={{ marginBottom: "8px",color:'#7F56D9' }}
      >
        <label style={{ fontSize: "18px", fontWeight: "bold" }}>RTMP Setup</label>
      </div>

      <div style={{ marginBottom: "8px", display: "flex", alignItems: "center" }}>
        <label
          htmlFor="rtmpSwitch"
          style={{ flex: "0 0 150px", fontWeight: "bold",marginRight:'16px' }}
        >
          RTMP Switch
        </label>
        <input
          type="checkbox"
          id="rtmpSwitch"
          style={{
            border: "1px solid #ccc",
            padding: "6px",
            borderRadius: "4px",
            fontSize: "12px"
          }}
        />
      </div>

      <div style={{ marginBottom: "8px", display: "flex", alignItems: "center" }}>
        <label
          htmlFor="rtmpUrl"
          style={{ flex: "0 0 150px", marginRight: "16px", fontWeight: "bold" }}
        >
          RTMP Url
        </label>
        <input
          type="text"
          id="rtmpUrl"
          value=""
          style={{
            border: "1px solid #ccc",
            padding: "6px",
            borderRadius: "4px",
            fontSize: "12px"
          }}
        />
      </div>

      <div style={{ marginBottom: "16px", display: "flex", alignItems: "center" }}>
  <label
    htmlFor="stream"
    style={{
      flex: "0 0 150px",
      marginRight: "16px",
      fontWeight: "bold",
      color: "#4A4A4A", // Subtle label color
      fontSize: "16px"  // Slightly larger font size for better readability
    }}
  >
    Stream
  </label>
  <select
    name="stream"
    id="stream"
    style={{
      padding: "8px 12px",
      border: "1px solid #ccc",
      borderRadius: "4px",
      fontSize: "14px",
      color: "#333",
      backgroundColor: "#fff",
      // appearance: "none", // Remove the default arrow style
      // boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)", // Subtle shadow for depth
    }}
  >
    <option value="MainStream">Main Stream</option>
    <option value="SubStream">Sub Stream 1</option>
  </select>
</div>


      <div>
        <button style={{
        backgroundColor: "#7F56D9",
        color: "white",
        padding: "10px 16px",
        border: "none",
        borderRadius: "4px",
        cursor: "pointer",
        fontWeight: "bold"
      }}>Save</button>
      </div>
    </div>
  );
}

export default RTMPSetup;
