import axios from "axios";

const baseURL = process.env.REACT_APP_BASE_URL;

const instance = axios.create({
  baseURL: baseURL,
  withCredentials: true,
});

instance.interceptors.response.use(
  response => {
    // If the response is successful, just return the response
    return response;
  },
  error => {
    // If the response has a status code of 401, redirect to the login page
    if (error.response && error.response.status === 401) {
      window.location.href = '/'; // Replace with your login route
    }
    // Otherwise, reject the promise with the error object
    return Promise.reject(error);
  }
);

// export async function getTotalCamera(currentPage) {
//   try {
//     const params = { customerId: currentPage };
//     const token = localStorage.getItem('token');

//     // const response = await instance.get('/allcamera', {
//     const response = await instance.get('/getTotalCamera', {
//       params: params,
//       headers: {
//         'Authorization': `Bearer ${token}`,
//         'Content-Type': 'application/json',
//         // Add any other headers if needed
//       },
//     });
//     return response;

//   } catch (error) {
//     throw error;
//   }
// }

export async function getCardData(currentPage) {
  try {
    const params = { customerid: currentPage };

    // const response = await instance.get('/allcamera', {
    const response = await instance.get('dashboard/getCardData', {
      params: params
    });
    return response;

  } catch (error) {
    throw error;
  }
}

export async function getPromptData(currentPage) {
  try {
    const params = { customerId: currentPage };

    // const response = await instance.get('/allcamera', {
    const response = await instance.get('dashboard/getPromptData', {
      params: params
    });
    return response;

  } catch (error) {
    throw error;
  }
}

export async function imageCount(customerid) {

  try {
    const params = { customerid: customerid };

    // Ensure the URL is correctly formatted
    const response = await instance.get('analytics/imageCount', {
      params: params
    });
    return response;

  } catch (error) {
    throw error;
  }
}


export async function getOnOffCameraData(customerid) {
  try {
    const params = { customerid: customerid };

    // const response = await instance.get('/allcamera', {
    const response = await instance.get('/count', {
      params: params,
    });
    return response;

  } catch (error) {
    throw error;
  }
}

export async function getChartDataByCustomerId(customerid) {
  try {
    const response = await instance.post('analytics/get-by-customerid',
      {
        customerid: customerid
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export async function getCardDataByPrompt(prompt) {
  // const token = localStorage.getItem('token');
  try {
    const response = await instance.post('dashboard/getPromptResult', {
      prompt: prompt,
      
    }
    );

    return response;
  } catch (error) {
    throw error;
  }
}

//////////////////////// SUBSCRIPTION /////////////////////////

export async function getPlans() {

  try {
    // const token = localStorage.getItem('token');
    const response = await instance.get('plan/getPlans', {
      // params: {
      //   page: page,
      //   resultPerPage:resultPerPage,
      //   customerId:customerId,
      //   shareId : gg.shareId,
      //   contentId :gg.contentId
      // }
    });
    // // console.log(response.data)
    return response.data;

  } catch (error) {
    throw error;
  }
}

export async function getAIPlans(plan) {

  try {
    // const token = localStorage.getItem('token');
    const response = await instance.get('plan/getAIPlans', {
      params: {
        plan: plan,
      }
    });
    // console.log("nago",response.data)
    return response.data;

  } catch (error) {
    throw error;
  }
}

export async function getDidForPlan(customerId) {
  try {
    // const token = localStorage.getItem('token');
    const response = await instance.get('camera/getDidForPlan',
      {
        params: {
          customerid: customerId,
        }
      }
    );
    // // console.log(response.data)
    return response.data;

  } catch (error) {
    throw error;
  }
}

export async function getFinalPrice(plan, aiFeatureType, aiFeatureName, selectedPlanOptions) {

  try {
    // const token = localStorage.getItem('token');
    const response = await instance.post('plan/getFinalPrice', {
      plan: plan,
      aiFeatureType: aiFeatureType,
      aiFeatureName: aiFeatureName,
      selectedPlanOptions: selectedPlanOptions,
    });
    // // console.log(response.data)
    return response.data;

  } catch (error) {
    throw error;
  }
}


