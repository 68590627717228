// import React, { useEffect, useState } from 'react';
// import styles from './AIEvents.module.css';
// import Header from './Header';
// import Sidebar from './Sidebar';
// import Calendar from './Calender';
// import EventFilter from './EventFilter';
// import EventCard from './EventCard';
// import { useNavigate } from 'react-router-dom';
// import { getAiModels } from '../../services/aiCamera';
// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// const AIEvents = () => {
//   const [models, setModel] = useState([]);
//   const [selectedModel, setSelectedModel] = useState('');
//   const [events, setEvents] = useState([]);

//   // Get today's date
//   const today = new Date();
//   const formattedTodayDate = today.toLocaleDateString('en-US', { day: 'numeric', month: 'short' });
//   const dayName = today.toLocaleDateString('en-US', { weekday: 'short' });

//   const eventDates = [
//     { date: formattedTodayDate, day: dayName },
//     { date: '16 Jul', day: 'Mon' },
//     { date: '17 Jul', day: 'Tue' },
//     { date: '18 Jul', day: 'Wed' },
//     { date: '20 Jul', day: 'Thu' },
//     { date: '21 Jul', day: 'Fri' },
//     { date: '22 Jul', day: 'Sat' },
//   ];

//   const navigate = useNavigate();

//   const arcisClick = () => {
//     navigate('/arcisgpt');
//   };

//   const notificationClick = () => {
//     navigate('/notification');
//   };

//   const reportclick = () => {
//     navigate('/reports');
//   };

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await getAiModels();
//         setModel(response.models);
//         console.log('AI Models:', response);
//       } catch (error) {
//         console.error('Error fetching AI models:', error);
//       }
//     };

//     fetchData();
//   }, []);

//   const handleModelChange = (model) => {
//     setSelectedModel(model);
//   };

//   // const handleEventsUpdate = (newEvents) => {
//   //   setEvents(newEvents);
//   //   console.log('Updated Events:', newEvents);
//   // };
//   const handleEventsUpdate = (newEvents) => {
//     if (!newEvents || newEvents.length === 0) {
//       toast.error('No events available');
//     } else {
//       setEvents(newEvents);
//       console.log('Updated Events:', newEvents);
//     }
//   };


//   return (
//     <main className={styles.eventsDesktop}>
//       <ToastContainer />
//       {/* <Header /> */}
//       <div className={styles.contentWrapper}>
//         {/* <Sidebar /> */}
//         <section className={styles.mainContent}>
//           <h1 className={styles.pageTitle}>AI Events</h1>
//           <p className={styles.pageDescription}>
//             List of events triggered by cameras
//           </p>
//           <div className={styles.tabGroup}>
//             <button className={`${styles.tab} ${styles.active}`} onClick={reportclick}>Events</button>
//             <button className={styles.tab} onClick={notificationClick}>Notifications</button>
//             <button className={styles.tab}>Reports</button>
//             <button className={styles.tab} onClick={arcisClick}>
//               ArcisGPT
//             </button>
//           </div>

//           <Calendar dates={eventDates} />
//           <EventFilter
//             model={models}
//             onModelChange={handleModelChange}
//             onEventsUpdate={handleEventsUpdate}
//           />

//           {/* {events.length > 0 ? (
//             <div className={styles.eventCardGrid}>
//               {events.map((event, index) => (
//                 <EventCard
//                   key={index}
//                   title={event.title}
//                   deviceId={event.deviceId}
//                   length={event.length}
//                   imageUrl={event.imgurl}
//                 />
//               ))}
//             </div>
//           ) : (
//             <p className={styles.noDataMessage}>No data available</p>
//           )} */}
//           {events.length > 0 ? (
//             <div className={styles.eventCardGrid}>
//               {events.map((event, index) => (
//                 <EventCard
//                   key={index}
//                   title={event.title}
//                   deviceId={event.deviceId}
//                   length={event.length}
//                   imageUrl={event.imgurl}
//                 />
//               ))}
//             </div>
//           ) : (
//             <p className={styles.noDataMessage}>No data available</p>
//           )}
//         </section>
//       </div>
//     </main>
//   );
// };

// export default AIEvents;


import React, { useEffect, useState } from 'react';
import styles from './AIEvents.module.css';
// import Header from '../Header/Header';
// import '../Header/Header.module.css';
// import Sidebar from '../Sidebar/NavigationMenu';
// import '../Sidebar/NavigationMenu.module.css';
import Calendar from '../../components/AIEvents/Calender';
import EventFilter from '../../components/AIEvents/EventFilter';
import EventCard from '../../components/AIEvents/EventCard';
import { useNavigate } from 'react-router-dom';
import { getSDCardData } from '../../services/sdcard';
import { Text } from '@chakra-ui/react';
import Modal from '../../components/ModalView/Modal';


const AIEvents = () => {
  const [data, setData] = useState(null)
  const [error, setError] = useState(null);
  const pageSize = 12; // Number of items per page
  const [currentPage, setCurrentPage] = useState(1);  
  const handlePageChange = (page) => {
    setCurrentPage(page);
    
  }

  const [selectedDate, setSelectedDate] = useState(() => getCurrentISTDate());

  const handleDateSelect = (selectedDate) => {
    
    setSelectedDate(selectedDate)
    // Handle the selected date here (e.g., fetch data for the selected date)
  };

  const navigate = useNavigate()
  const arcisclick = () => {
    navigate('/arcisgpt');
  };

  const notifyclick = () => {
    navigate('/notification');
  };

  const reportclick = () => {
    navigate('/reports');
  };
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [TypeFlags2, setTypeFlags2] = useState('0x2');

  const handleDeviceSelect = (device) => {
    setSelectedDevice(device);
  };

  const handleAiSelect = (selectedai) => {
    setTypeFlags2(selectedai);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setData(null);
        const data = await getSDCardData(currentPage,selectedDevice.deviceid,selectedDate,TypeFlags2,pageSize);

        const updatedData = {
          ...data,
          Item: data.Item.map(item => ({
            ...item,
            source: "Edge"
          }))
        };
        setData(updatedData);

      
      } catch (error) {
        setError(error.message);
      }
    };

    fetchData();
  }, [currentPage,selectedDevice,selectedDate,TypeFlags2]);

  const [selectedUrl, setSelectedUrl] = useState(null);
  const handleGoBack = () => {
    setSelectedUrl(null); // Set selectedDevice to null to go back to the device list
  };

  const [isModalOpen, setIsModalOpen] = useState(false); 

  const handleUrlSelection = (url) => {
    setSelectedUrl(url);
    setIsModalOpen(true)
    // You can also perform other actions with the URL here

  };

  const handleCloseModal = () => setIsModalOpen(false);
  return (
    <main className={styles.eventsDesktop}>
    {selectedUrl && isModalOpen && (
        <div className={styles.modalContainer} onClick={(e) => e.stopPropagation()}>
          <Modal url={selectedUrl} device={selectedDevice} status={selectedDevice.status} onClose={handleCloseModal} />
        </div>
      )}
      {/* // <div style={{ marginLeft: '270px' }}>
       
      //   <DeviceComponent 
      //   url={selectedUrl} 
      //   device={selectedDevice} goBack={handleGoBack} status={selectedDevice.status} />
      //   </div>
      // ) : ( */}
      <div className={styles.contentWrapper}>
        
        <section className={styles.mainContent}>
          <h1 className={styles.pageTitle}>AI Events</h1>
          <p className={styles.pageDescription}>
            List of events triggered by cameras
          </p>
          <div className={styles.tabGroup}>
        <button className={`${styles.tab} ${styles.active}`}>Events</button>
        <button className={styles.tab} onClick={notifyclick}>Notifications</button>
        <button className={styles.tab} onClick={reportclick}>Reports</button>
        <button className={styles.tab}onClick={arcisclick}>ArcisGPT</button>
      </div>
      
          <Calendar onDateSelect={handleDateSelect} onDeviceSelect={handleDeviceSelect} />
          <EventFilter onAiSelect={handleAiSelect} onPageChange={handlePageChange}/>

          <div className={styles.eventCardGrid}>
            {data && data.Item && data.Item.length > 0 ? (
              data.Item.map((item, index) => {
                
                const duration = item.TimeEnd - item.TimeStart;
                // const title = `Channel ${item.Channel}`;
                const length = `${Math.floor(duration / 60)} min ${duration % 60} sec`;

                return (
                  <EventCard
                    key={index}
                    // {...item}
                    title={item.Type}
                    TimeStart={item.TimeStart}
                    TimeEnd={item.TimeEnd}
                    deviceId={selectedDevice.deviceid}
                    name={selectedDevice.cameraname}
                    length={length}
                    source={item.source}
                    imageUrl={`https://cdn.builder.io/api/v1/image/assets/TEMP/defa8084f16f3d77ef8c8e959e418aeaac57dfe4712fb68ebdc3a61c0785f8f1?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1`}
                    onUrlSelect={handleUrlSelection}
                  />
                );
              })
            ) : (
              <p>No data available</p>
            )}
          </div>
        </section>
      </div>
      {/* // )} */}
    </main>
  );
};



const formatDateToISO = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

// Utility function to get current IST date
const getCurrentISTDate = () => {
  const offset = 5.5; // IST is UTC+5:30
  const localDate = new Date();
  const utcDate = new Date(localDate.getTime() + (localDate.getTimezoneOffset() * 60000));
  const istDate = new Date(utcDate.getTime() + (offset * 3600000));
  return formatDateToISO(istDate);
};

export default AIEvents;