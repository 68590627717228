import React, { useState } from 'react';
import styles from '../Settings.module.css';
import { IoSettingsOutline, IoWifi } from "react-icons/io5";
import { LuBadgeHelp } from "react-icons/lu";
import { MdContentCopy } from "react-icons/md";
import Video_Setup from './Video_Setup';
import Image_Setup from './Image_Setup';
import ISP_Setup from './ISP_Setup';
import PrivacyMask_Setup from './PrivacyMask_Setup';
import OSD_Setup from './OSD_Setup';


function Media({selectedMedia, deviceId, onSave }) {



    return(
        <div className={styles.mediaContent}>
        {selectedMedia === 'Video Setting' && <Video_Setup deviceId={deviceId} onSave={onSave} />}
        {selectedMedia === 'Image Setting' && <Image_Setup deviceId={deviceId} onSave={onSave} />}
        {selectedMedia === 'ISP' && <ISP_Setup  deviceId={deviceId} onSave={onSave} />}
        {selectedMedia === 'Privacy Mask' && <PrivacyMask_Setup deviceId={deviceId} onSave={onSave} />}
        {selectedMedia === 'OSD' && <OSD_Setup deviceId={deviceId} onSave={onSave} />}
        </div>
    );

}

export default Media;
