import React from 'react';
import styles from './MetricItem.module.css';

function MetricItem({ title, value, change, changeType, comparisonText, chartSrc, IconComponent }) {
  return (
    <article className={styles.metricItem}>
      <div className={styles.metricHeader}>
        <h3 className={styles.metricTitle}>{title}</h3>
        <button className={styles.optionsButton} aria-label="More options">
          <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/19ffaa00b5e7f19d840941aeae7ac93e603bc72c48a88d75c54f5151037e9ee7?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1" alt="" className={styles.optionsIcon} />
        </button>
      </div>
      <div className={styles.metricContent}>
        <div className={styles.metricData}>
          <p className={styles.metricValue}>{value}</p>
          <div className={styles.metricChange}>
            <span className={`${styles.changeValue} ${styles[changeType]}`}>
              <img 
                src={changeType === 'positive' ? "https://cdn.builder.io/api/v1/image/assets/TEMP/54f4f09d8e121d5f9f151ec78d091659a0e0722ce776c2f3cb7bd833591c822a?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1" : "https://cdn.builder.io/api/v1/image/assets/TEMP/afc716775ba8c5337bd2dd456a5f8a4486799086d9af11764ef80487017f5b74?placeholderIfAbsent=true&apiKey=21c2172730ed406bb6f91788633e80d1"} 
                alt="" 
                className={styles.changeIcon} 
              />
              {change}
            </span>
            <span className={styles.comparisonText}>{comparisonText}</span>
          </div>

        </div>
        <img src={chartSrc} alt="" className={styles.metricChart} />
        {/* {IconComponent ? (
          <IconComponent className={styles.metricChart} />
        ) : null} */}

      </div>
    </article>
  );
}

export default MetricItem;